
::-moz-placeholder {
	opacity:1;
	color:#a9b4c6;
}
:-ms-input-placeholder {
	color:#a9b4c6;
}
::-webkit-input-placeholder {
	color:#a9b4c6;
}
.placeholder {
	color:#a9b4c6;
}
input:focus, button, a, a:focus, a:active , textarea:focus {
	outline: 0;
	outline: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-tap-highlight-color: transparent; 
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
button, input[type="button"],input[type="reset"],input[type="submit"] {
	-webkit-appearance:button;
	cursor:pointer;
	overflow:visible;
}
.btn:focus,.btn:visited {
	outline:none;
}
button,input,select,textarea {
	font-family:inherit;
	font-size:100%;
	font-weight:inherit;
	margin:0;
	vertical-align:middle;
	outline:none;
}