
//@import "_fonts.scss";
@import "_normalize.scss";
@import "../sprites/sprite.scss";


img {
	border: 0;
	max-width:100%;
}
a {
	text-decoration: none;
	transition: all 0.3s ease;
	&:hover, &:focus {
		text-decoration: none;
	}
}
*, p {
	margin:0;
}
p {
	a {
		text-decoration: underline;
		color: #00b7e9;
		&:hover {
			color: #00b7e9;
			text-decoration: none;
		}
	}
}
ul {
	list-style: none;
	margin:0;
	padding:0;
}
.text-uppercase {
	text-transform: uppercase;
}
.btn-b {
	display: inline-block;
	background: #00b7e9;
	color: #fff;
	font-size: 19px;
	padding:13px 48px;
	font-weight:500;
	margin:0 0 0 6px;
	position: relative;
	&:hover {
		background: #62ccf5;
		color: #fff;
	}
	&:focus {
		color: #fff;
	}
	&:before {
		content: '';
		position: absolute;
		display: block;
		background: #0098c4;
		width:6px;
		height:100%;
		left:-6px;
		top: 0;
		transform: skewY(-45deg);
		transform-origin: 100% 100%;
	}
	&:after {
		content: '';
		position: absolute;
		display: block;
		background: #007a9f;
		width:100%;
		height:6px;
		left:-6px;
		bottom: -6px;
		transform: skewX(-45deg);
		transform-origin: 100% 100%;
	}
}


html {
	height:100%;
	min-height:100%;
	min-width:100%;
	-webkit-text-size-adjust: 100%;
}
body {
	background: #fff;
	font: 17px/1.3 'Roboto', sans-serif;
	font-weight: 400;
	padding: 0;
	margin: 0 auto;
	color: #000;
}
.text-right {
	text-align: right;
}

@media (min-width: 1201px) {
	.container {
		width: 1200px;
	}
	.main-mnu_1 {
		display: none !important;
	}
}
@media (min-width: 1441px) {
	.container {
		width: 1440px;
	}
}
@media (min-width: 1200px) {
	.visible-lg {
		display: inline-block !important;
	}
}
//.container {
//	padding-right: 15px;
//	padding-left: 15px;
//}
//.row {
//	margin-right: -15px;
//	margin-left: -15px;
//}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
	//padding-right: 10px;
	//padding-left: 10px;
	//display: inline-block;
	//vertical-align: top;
}


/*bootstrap grid inline-block*/
.row {
	font-size: 0;
}

.col-lg-1, .col-lg-10, .col-lg-11,
.col-lg-12, .col-lg-2, .col-lg-3,
.col-lg-4, .col-lg-5, .col-lg-6,
.col-lg-7, .col-lg-8, .col-lg-9,
.col-md-1, .col-md-10, .col-md-11,
.col-md-12, .col-md-2, .col-md-3,
.col-md-4, .col-md-5, .col-md-6,
.col-md-7, .col-md-8, .col-md-9,
.col-sm-1, .col-sm-10, .col-sm-11,
.col-sm-12, .col-sm-2, .col-sm-3,
.col-sm-4, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9,
.col-xs-1, .col-xs-10, .col-xs-11,
.col-xs-12, .col-xs-2, .col-xs-3,
.col-xs-4, .col-xs-5, .col-xs-6,
.col-xs-7, .col-xs-8, .col-xs-9 {
	float: none;

}

.vertical-middle {
	vertical-align: middle!important;
}
.vertical-bottom {
	vertical-align: bottom!important;
}

@media screen and (min-width: 1200px){
	.col-lg-1, .col-lg-10, .col-lg-11,
	.col-lg-12, .col-lg-2, .col-lg-3,
	.col-lg-4, .col-lg-5, .col-lg-6,
	.col-lg-7, .col-lg-8, .col-lg-9 {
		display: inline-block;
		vertical-align: top;
		font-size: 17px;
	}
}

@media screen and (min-width: 992px){
	.col-md-1, .col-md-10, .col-md-11,
	.col-md-12, .col-md-2, .col-md-3,
	.col-md-4, .col-md-5, .col-md-6,
	.col-md-7, .col-md-8, .col-md-9 {
		display: inline-block;
		vertical-align: top;
		font-size: 17px;
	}
}

@media screen and (min-width: 768px){
	.col-sm-1, .col-sm-10, .col-sm-11,
	.col-sm-12, .col-sm-2, .col-sm-3,
	.col-sm-4, .col-sm-5, .col-sm-6,
	.col-sm-7, .col-sm-8, .col-sm-9 {
		display: inline-block;
		vertical-align: top;
		font-size: 17px;
	}
}

@media screen and (min-width: 200px){
	.col-xs-1, .col-xs-10, .col-xs-11,
	.col-xs-12, .col-xs-2, .col-xs-3,
	.col-xs-4, .col-xs-5, .col-xs-6,
	.col-xs-7, .col-xs-8, .col-xs-9 {
		display: inline-block;
		vertical-align: top;
		font-size: 17px;
	}
}

.header_top {
	padding-top: 25px;
	padding-bottom: 25px;
	.row {
		position: relative;
	}
}
.logo {
	overflow: hidden;
	font-weight:500;
	color: #203662;
	&:hover {
		opacity:0.7;
	}
	img {
		width: 100%;
		max-width: 180px;
		display: inline-block;
		vertical-align: middle;
	}
	p {
		font-size: 18px;
		display: inline-block;
		vertical-align: bottom;
		margin: 0 0 0 10px;
		line-height: 20px;
		max-width:45%;
	}
}
.wrap-logo-f img {
	max-width: 180px;
}
.right-block {
	margin: 8px 0 0;
	&>div {
		display: inline-block;
		vertical-align: middle;
	}
	& > .mob-menu {
		display: none;
	}

}
.right-block {
	display: inline-block;
	vertical-align: middle;
}
.menu-top {
	ul {
		li {
			display: inline-block;
			vertical-align: middle;
			margin:0 0 0 55px;
			&:first-child {
				margin:0;
			}
			&.current-menu-item {
				font-weight:600;
			}
			&.current-post-ancestor {
				font-weight:600;
			}
			a {
				color: #151f34;
				font-size: 18px;
				&:hover {
					color: #6f6f6f;
				}
				&:active {
					color: #151f34;
				}
			}
		}
	}
}
.wrap-email, .wrap-email-contacts {
	margin:0 0 0 70px;
	a {
		color: #00b7e9;
		font-weight:700;
		font-size: 21px;
		&:hover {
			opacity:0.7;
		}
	}
	.icon-email {
		display: inline-block;
		vertical-align: middle;
		width:28px;
		height:21px;
		margin: 0 10px 0 0;
		background: url("../img/svg/icon-mail.svg") no-repeat 0 0;
	}
}
.wrap-search {
	margin:0 0 0 30px;
	position: relative;
	.icon-search {
		width: 23px;
		height:25px;
		display: inline-block;
		vertical-align: top;
		cursor: pointer;
		background: url("../img/svg/icon-search.svg") no-repeat 0 0;
		&:hover {
			opacity: 0.7;
		}
	}
	input {
		display: none;
		position: absolute;
		right:0;
		top:-12px;
		border: 2px solid #00b7e9;
		padding: 12px 17px;
		color: #000;
		font-weight:500;
		width:275px;
		&.active {
			display: block;
		}
	}
}
.wrap-email-contacts {
	padding:20px 10px;
	border: 2px solid #00b7e9;
	text-align: center;
	margin:0;
	a {
		font-size:24px;
	}
}

.toggle-mnu_1 {
	display: block;
	width: 30px;
	span {
		display: block;
		width: 100%;
		height: 4px;
		margin:0 0 7px;
		background: #02b7e9;
		transition: all 0.3s;
		&:last-child {
			margin:0;
		}
	}
}
.mob-menu {
	margin: 0 0 0 50px;
}
.main-mnu_1 {
	font-size: 17px;
	padding:40px 30px;
	border: 2px solid #00b7e9;
	box-shadow:0 0 9px rgba(0,0,0,0.23);
	text-align: left;
	position: absolute;
	top: -15px;
	right: 0;
	background: #fff;
	z-index: 5;
	width: 100%;
	max-width: 370px;
	display: none;
	.wrap-search input {
		width:255px;
	}
}
.mob-list {
	li {
		margin:0 0 35px;
		&:last-child {
			margin:0;
		}
		&.current-menu-item a{
			font-weight: 600;
		}
		&.current-post-ancestor a{
			font-weight: 600;
		}
		a {
			color: #151f34;
			font-weight: 400;
			font-size: 17px;
		}
	}
}

.mob-list-1 {
	margin:0 0 30px;
	display: none;
		.menu > li {
			border-top: 2px solid #d2d9e5;
			padding: 20px 10px;
			position: relative;
			&:last-child {
				border-bottom: 2px solid #d2d9e5;
			}
			&.menu-item-has-children  {
				&:before {
					content: '';
					position: absolute;
					right: 10px;
					top: 25px;
					width: 0;
					height: 0;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
					border-top: 7px solid #a9b4c6;
				}
			}
			&.menu-item-has-children.opened-sub-menu {
				&:before {
					width: 0;
					height: 0;
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
					border-bottom: 7px solid #02b7e9;
					border-top: none;
				}
			}
			&.current-menu-item a,
			&.current-post-ancestor a{
				font-weight: 600;
				color: #02b7e9;
			}
			&.current-menu-item li a,
			&.current-post-ancestor li a{
				font-weight: 400;
				color: #151f34;
			}
			&.current-menu-parent,&.current-post-ancestor  {
				li{
					&.current-menu-item  a,
					&.current-post-ancestor a{
						color: #02b7e9;
						font-weight: 500;
					}
					a {
						color: #151f34;
						font-weight: 400;
					}
				}
				a{
					font-weight: 500;
					color: #02b7e9;
				}
			}
			a {
				font-size: 14px;
				text-transform: uppercase;
				color: #151f34;
				font-weight: 400;
			}
			.sub-menu {
				padding: 15px 0 0 20px;
				position: static;
				background: transparent;
				box-shadow: none;
				border: none;
				li {
					margin:0 0 15px;
					&:last-child {
						margin: 0;
					}
					a {
						font-size: 13px;
						text-transform: none;
					}
				}
			}
		}
}
.wrap_mail-search {
	padding:0 65px 65px 0;
	&:after {
		content: '';
		clear: both;
		display: block;
	}
	.wrap-search {
		float: left;
	}
	.wrap-email {
		float: left;
		margin:0;
	}
}
.close-menu {
	width:29px;
	height:29px;
	position: absolute;
	right:10px;
	top:35px;
	display: block;
	background: url("../img/svg/close.svg") no-repeat 0 0;
}
.header_bottom {
	background: #00b7e9;
	.fz {
		font-size:0;
	}
	.wrap-logo {
		display: none;
	}
	&.fixed {
		position: fixed;
		width:100%;
		top:0;
		z-index:99;
		.wrap-logo {
			display: inline-block;
			vertical-align: middle;
			width:17%;
			a:hover {
				opacity:0.8;
			}
		}
		.wrap-nav {
			width:83%;
			display: inline-block;
			vertical-align: middle;
		}
	}
}
.nav-menu {
	text-align: center;
	.menu {
		text-align: center;
		border: 2px solid #151f34;
		border-top: 0;
		font-size:0;
		border-bottom: 0;
		& > li {
			display: inline-block;
			vertical-align: top;
			width: 25%;
			border-right: 2px solid #151f34;
			position: relative;
			&:last-child {
				border: 0;
			}
			&.current-menu-item,
			&.current-menu-parent,
			&.current-post-ancestor{
				background: #00a4da;
			}
			&.menu-item-has-children:active {
				background: #62ccf5;
			}
			&.menu-item-has-children:hover {
				.sub-menu {
					display: block;
				}
			}
			&:hover {
				background: #62ccf5;
			}
			&:active {
				background: #00a4da;
			}
			& > a {
				font-size: 20px;
				display: block;
				text-transform: uppercase;
				font-weight: 700;
				color: #151f34;
				padding: 17px 10px;
			}
		}
	}
}
.sub-menu {
	display: none;
	position: absolute;
	z-index:2;
	top:100%;
	left:0;
	width:100%;
	background: #62ccf5;
	padding:35px 38px;
	text-align: left;
	margin:-2px 0 0;
	border-top: 2px solid #7cd5f9;
	box-shadow: -2px 2px 4px rgba(0,0,0,0.23), 2px 2px 4px rgba(0,0,0,0.23);
	li {
		margin: 0 0 35px;
		&:last-child {
			margin:0;
		}
		&.current-menu-item {
			a {
				color: #fff;
			}
		}
		a {
			font-size:20px;
			color: #151f34;
			font-weight:500;
			&:hover {
				color: #304368;
			}
			&:active {
				color: #fff;
			}
		}
	}
}

.h1 {
	font-size: 94px;
	color: #0d2556;
	font-weight:500;
	margin:0;
}
.wrap-h1 {
	margin:0;
	.h1 {
		font-size: 50px;
		margin:0 0 40px;
	}
}
.text-p {
	margin:0 0 25px;
}

.wrap-home-block {
	background: #151f34;
	.home-block {
		margin: 60px 40px 40px;
		min-height: 550px;
		padding: 60px 0 0 85px;
		position: relative;
		background: url("../img/svg/bg-home-block.svg") no-repeat center center;
		.h1 {
			color: #00b7e9;
		}
	}
}
.toBottom {
	position: absolute;
	bottom:30px;
	left: 50px;
	width:19px;
	height:51px;
	background: url("../img/icon-toBottom.png") no-repeat 0 0;
}



.wrap-cases {
	background: url("../img/bg.jpg") repeat;
	padding:110px 0 60px;
}
.shop-page {
	padding:80px 0 30px;
	.h2 {
		font-size: 37px;
	}
	.case {
		a {
			padding:45px 30px 20px;
			min-height: 170px;
			.h2 {
				margin:0 0 20px;
			}
		}
		p {
			height: 57px;
		}
	}
}
.h2 {
	color: #0d2556;
	font-size: 46px;
	font-weight:500;
	margin:0 0 20px;
}
.case {
	margin:0 0 60px 10px;
	position: relative;
	a {
		display: block;
		background: #151f34;
		padding: 30px 45px 20px 0;
		min-height: 250px;
		&:hover {
			background: #16283f;
		}
	}
	&:before {
		content: '';
		position: absolute;
		display: block;
		background: #25314d;
		width:10px;
		height:100%;
		left:-10px;
		top: 0;
		transform: skewY(-45deg);
		transform-origin: 100% 100%;
	}
	&:after {
		content: '';
		position: absolute;
		display: block;
		background: #0c101e;
		width:100%;
		height:10px;
		left:-10px;
		bottom: -10px;
		transform: skewX(-45deg);
		transform-origin: 100% 100%;
	}
	.wrap-img {
		text-align: center;
		img {
			max-width:100%;
			height:180px;
			padding: 0 0 0 15px;
		}
	}
	p {
		color: #fff;
		margin:0;
		font-size: 16px;
		height:130px;
		overflow: hidden;
	}
	.h2 {
		color: #00b7e9;
	}
}

.h3 {
	font-size: 52px;
	color: #0d2556;
	margin:0 0 40px;
}
.about-project {
	padding: 75px 0 100px;
	.wrap-text {
		margin:0 75px 40px 0;
		p {
			margin:0 0 10px;
		}
	}
	img {
		vertical-align: bottom;
		max-width:540px;
		width:100%;
	}
	.mobile-img {
		padding:0 15%;
		margin:0 0 17px;
	}
}

.bg-gray {
	background: #f4f5f8;
	padding: 75px 0 100px;
}
.post {
	margin:0 0 60px;
	.post_wrap-img {
		margin:0 0 20px;
		text-align: center;
		img {
			max-width:100%;
			width: 100%;
		}
	}
	.h3 {
		font-size: 24px;
		height: 56px;
		overflow: hidden;
		margin:0 0 15px;
	}
	.post-date {
		color: #8892a0;
		font-size: 16px;
		margin:0 0 20px;
	}
	p {
		font-size: 17px;
		color: #000;
	}
}

#footer {
	background: #151f34;
	.f-left {
		padding:45px 0 30px;
		background: #00b7e9;
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			left: -500%;
			top: 0;
			width: calc(100% *5);
			height:100%;
			background: #00b7e9;
		}
	}
	.f-right {
		padding:45px 0 30px;
		.margin {
			margin-bottom: 45px;
		}
		.made {
			margin:7px 0 0;
			span {
				display: block;
				color: #d7d7d7;
				margin:0 0 10px;
				font-weight: 400;
				font-size: 13px;
				&:last-child {
					margin:0;
				}
			}
			a {
				color: #d7d7d7;
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.wrap-email {
		margin:0;
		text-align: right;
		a {
			font-size: 19px;
			list-height: 22px;
		}
	}
}
.nav-l-footer {
	margin:0 0 70px;
	li {
		display: inline-block;
		vertical-align: top;
		margin:0 30px 0 0;
		&:last-child {
			margin:0;
		}
		a {
			font-size: 16px;
			text-transform: uppercase;
			color: #151f34;
			font-weight:700;
			&:hover {
				color: #304368;
			}
		}
	}
}

.logo_made {
	.wrap-logo-f {
		display: inline-block;
		vertical-align: middle;
		width:42%;
	}
	.made {
		display: inline-block;
		vertical-align: middle;
		width:49%;
		text-align: right;
		span {
			display: inline-block;
			vertical-align: middle;
			color: #000;
			margin:0 0 10px;
			font-weight: 500;
			font-size: 15px;
			&:last-child {
				margin:0;
			}
		}
		a {
			color: #000;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
.nav-r-footer {
	padding:0 0 0 20px;
	li {
		display: inline-block;
		vertical-align: top;
		margin:0 35px 0 0;
		&:last-child {
			margin:0;
		}
		&.current-menu-item {
			a {
				font-weight: 600;
			}
		}
		a {
			color: #fff;
			font-size: 18px;
			&:hover {
				color: #d3d8e4;
			}
		}
	}
}
.copyright {
	color: #a9b4c6;
	text-align: right;
	line-height: 27px;
	font-size:15px;
	span {
		display: inline-block;
		vertical-align: top;
		position: relative;
		padding:0 13px 0 0;
		margin:0 7px 0 0;
		&:after {
			content: '';
			display: block;
			position: absolute;
			right:0;
			top:50%;
			width: 4px;
			height: 4px;
			margin:-2px 0 0;
			background: #a9b4c6;
			transform: rotate(45deg);
		}
	}
	a {
		color: #a9b4c6;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}


.social {
	padding:0 0 0 20px;
	a {
		display: inline-block;
		vertical-align: top;
		width:46px;
		height:46px;
		margin:0 15px 0 0;
		&:hover {
			opacity: 0.8;
		}
		&:last-child {
			margin:0;
		}
		img {
			width:100%;
		}
	}
}


.top-button {
	display: none;
	padding:16px 20px;
	background: #fff;
	position: fixed;
	right: 13px;
	bottom: 30px;
	transition: none;

	&:hover {
		opacity:0.8;
	}
	span {
		display: block;
		width:30px;
		height:17px;
		background: url("../img/shevron.png") no-repeat 0 0;
	}
}


.first-block {
	background: #151f34;
	padding:30px 0 40px;
	.h1 {
		font-size:50px;
		margin:0 0 20px;
		color: #00b7e9;
	}
	p {
		color: #fff;
	}
}
.f-block-img {
	text-align: center;
	img {
		max-height: 180px;
	}
}


.wpar-all-items {
	padding:60px 0;
	&.pd {
		padding:0 0 40px;
	}

}
.filter {
	margin:0 0 35px;
	.filter-head {
		display: inline-block;
		vertical-align: middle;
		color: #a9b4c6;
		margin:0 20px 0 0;
	}
}
.custom-select-wrapper {
	display: inline-block;
	vertical-align: middle;
}
.custom-select-box {
	position: relative;
}
.custom-select-button {
	text-overflow: ellipsis;
	border: 2px solid #00b7e9;
	width:235px;
	padding:12px 40px 12px 19px;
	position: relative;
	cursor: pointer;
	&:after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid #00b7e9;
		position: absolute;
		right:18px;
		top:50%;
		margin:-4px 0 0;
	}
	&.custom-select-opened {
		&:after {
			border-top: 0;
			border-bottom: 8px solid #00b7e9;

		}
	}
}
.custom-select-ul {
	position: absolute;
	top: 100%;
	width:100%;
	left:0;
	padding:25px 19px;
	border: 2px solid #00b7e9;
	border-top: 0;
	z-index:9;
	max-height: 355px;
	overflow: auto;
	background: #fff;
	li {
		text-overflow: ellipsis;
		margin:0 0 20px;
		cursor: pointer;
		&:last-child{
			margin:0 0 20px;
		}
		&:hover {
			color: #555555;
		}
		&:active {
			color: #00b7e9;
		}
	}
}

.filter {
	.custom-select-ul {
		 li:nth-child(2){
			 margin:0 0 30px;
			 font-weight: 600;
		}
		li:first-child{
			font-weight: 600;
		}
	}
}
.g-item {
	margin:0 0 50px;
	position: relative;
	&:before {
		content: '';
		position: absolute;
		display: none;
		background: #0098c4;
		width:6px;
		height:100%;
		left:-6px;
		top: 0;
		transform: skewY(-45deg);
		transform-origin: 100% 100%;
	}
	&:after {
		content: '';
		position: absolute;
		display: none;
		background: #007a9f;
		width:100%;
		height:6px;
		left:-6px;
		bottom: -6px;
		transform: skewX(-45deg);
		transform-origin: 100% 100%;
	}
	.vr {
		color: #151f34;
		letter-spacing: -0.7px;
		height:42px;
		overflow: hidden;
		&.dop-class {
			height: 65px;
		}
	}
}
.g-item-link {
	color: #000;
	transition: all 0s ease;
	&:hover {
		color: #fff;
		.g-item {
			background: rgba(0,183,233,0.9);
			&:after, &:before {
				display: block;
			}
		}
		.g-item_about, .g-item_about-top, .g-item-img {
			border-color: #02a8d7;
		}
		.h2 {
			color: #fff;
		}
		.hover-block {
			display: block;
		}
		.vr {
			color: #fff;
		}
	}
}
.g-item-img {
	height:300px;
	line-height: 290px;
	text-align: center;
	overflow: hidden;
	position: relative;
	border: 2px solid #d2d9e5;
	img {
		display: inline-block;
		vertical-align: middle;
		max-width: 100%;
		height:auto;
	}
	.hover-block {
		display: none;
		line-height: 1.3;
		position: absolute;
		top:0;
		left:0;
		height:100%;
		width:100%;
		padding:30px 20px;
		color: #fff;
		text-align: left;
		background: rgba(0,183,233,0.9);
		p {
			position: absolute;
			bottom: 30px;
			left:20px;
			right:20px;
			margin:0;
			max-height: 130px;
			overflow: hidden;
		}
	}
}

.hover-block_top {
	text-align: right;
	&>div {
		margin:0 0 15px;
	}
	i {
		display: inline-block;
		vertical-align: middle;
		margin:-8px 0 0 15px;
		&.icon-R {
			@include sprite($icon-r);
		}
		&.icon-user {
			@include sprite($icon-user);
		}
	}
}
.g-item_about {
	border: 2px solid #d2d9e5;
	border-top: 0;
	.g-item_about-top {
		padding:20px;
		border-bottom: 2px solid #d2d9e5;
	}
	.h2 {
		font-size: 24px;
		color: #0d2556;
		font-weight:bold;
		height:60px;
		overflow: hidden;
		margin:0 0 10px;
	}
}

.wrap-company-item {
	padding:80px 0 40px;
}
.c-item-link {
	color: #000;
	transition: all 0s ease;
	position: relative;
	display: block;
	margin:0 0 50px;
	&:before {
		content: '';
		position: absolute;
		display: none;
		background: #0098c4;
		width:6px;
		height:100%;
		left:-6px;
		top: 0;
		transform: skewY(-45deg);
		transform-origin: 100% 100%;
	}
	&:after {
		content: '';
		position: absolute;
		display: none;
		background: #007a9f;
		width:100%;
		height:6px;
		left:-6px;
		bottom: -6px;
		transform: skewX(-45deg);
		transform-origin: 100% 100%;
	}
	&:hover {
		color: #fff;
		background: rgba(0,183,233,0.9);
		&:before, &:after {
			display: block;
		}
		.h2 {
			color: #fff;
		}
		.c-wrap-img {
			border-color: #02a8d7;
			&:before {
				display: block;
			}
		}
		.c-item-about, .vr, .company-item {
			border-color: #02a8d7;
			color: #fff;
		}
	}
}
.company-item {
	border: 2px solid #d2d9e5;
	font-size:0;
	display: inline-block;
	vertical-align: top;
	width:100%;
	.c-wrap-img {
		display: inline-block;
		width:30%;
		border-right: 2px solid #d2d9e5;
		margin:0 -2px 0 0;
		position: relative;
		&:before {
			content: '';
			display: none;
			position: absolute;
			width:100%;
			height:100%;
			top:0;
			left:0;
			background: rgba(0,183,233,0.9);
		}
	}
	.c-item-about {
		display: inline-block;
		border-left: 2px solid #d2d9e5;
		width:70%;
		font-size:17px;
	}
}
.c-item-about {
	.h2 {
		font-size: 24px;
		margin:0 0 20px;
	}
	.adr {
		margin:0 0 70px;
	}
	.vr {
		border-top: 2px solid #d2d9e5;
		margin: 0 -4px 0 0;
	}
}
.c-item-about-top {
	padding: 20px 20px 20px;
	p {
		margin:0;
	}
}

.adr {
	height:22px;
	overflow: hidden;
	.cube {
		display: inline-block;
		vertical-align: top;
		width:14px;
		height:14px;
		background: #000;
		margin: 4px 10px 0 0;
		&.yellow {
			background: #ffed00;
		}
		&.red {
			background: #e30513;
		}
		&.green {
			background: #00aa00;
		}
	}
}

.vr {
	padding:10px 19px;
	font-weight:bold;
	color: #151f34;
}

.breadcrumbs {
	margin: 50px 0 20px;
	li {
		padding:0 25px 0 0;
		margin:0 12px 0 0;
		position: relative;
		display: inline-block;
		vertical-align: top;
		color: #a9b4c6;
		font-weight: 500;
		&:after {
			content: '';
			display: block;
			position: absolute;
			right:0;
			top:50%;
			margin:-6px 0 0;
			width:11px;
			height:11px;
			background: url("../img/svg/romb_kroshki.svg") no-repeat 0 0;
		}
		&:last-child {
			font-weight: 500;
			&:after {
				display: none;
			}
			a {
				font-weight:400;
			}
		}
		a {
			color: #a9b4c6;
			&:hover {
				color: #6f6f6f;
			}
		}
	}
}

.club {
	padding:10px 0 55px;
	.wrap-img-podrobno {
		margin:0 0 30px;
		text-align: center;
	}
	.h1 {
		font-size: 50px;
		border-bottom: 2px solid #d2d9e5;
		padding:0 0 35px;
		margin:0 0 25px;
	}
	img {
		height:auto !important;
	}
}
.about-club {
	.vr {
		padding:0 0 25px;
		border-bottom: 2px solid #d2d9e5;
		margin:0 0 30px;
	}
	.text {
		margin:0 0 40px;
		p {
			line-height: 26px;
		}
	}
}

.about-club_contacts {
	padding:0 0 30px;
	border-bottom: 2px solid #d2d9e5;
	margin:0 0 25px;
	.email {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	.phone, .email {
		color: #000;
		margin:0 0 25px;
		display: inline-block;
	}
	&>div {
		//margin:0 0 5px;
	}
}

.info-cl {
	padding:0 0 15px;
	margin:0 0 30px;
	border-bottom: 2px solid #d2d9e5;
	div {
		margin:0 0 15px;
		i {
			display: inline-block;
			vertical-align: top;
			margin:0 20px 0 0;
			&.icon-r-blue {
				@include sprite($icon-r-blue);
			}
			&.icon-user-blue {
				@include sprite($icon-user-blue);
			}
			&.icon-card {
				@include sprite($icon-card);
			}
			&.icon-delivery {
				@include sprite($icon-delivery);
			}
		}
	}
}


.about-project-block {
	padding:60px 0 0;

}
.info-project {
	margin:0 0 45px;
}
.wrap-list {
	padding:0 0 20px;
}
.content-box {
	ul {
		margin: 20px 0 10px 40px;
		li {
			position: relative;
			padding: 0 0 0 25px;
			margin: 0 0 15px;
			&:last-child {
				margin: 0;
			}
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 5px;
				width: 13px;
				height: 13px;
				background: url("../img/svg/icons_romb_blue.svg") no-repeat 0 0;
			}
		}
	}
	blockquote {
		text-align: center;
		padding: 0;
		border-left: none;
		margin: 40px 0;
		p {
			font-size: 24px;
			font-weight:700;
			line-height: 1.3;
		}
	}
}

.wrap-icon-cube {
	text-align: center;
	margin:40px 0;
	span {
		display: inline-block;
		vertical-align: middle;
		margin:0 25px 0 0;
		width: 20px;
		height: 20px;
		background: url("../img/svg/icons_romb_blue.svg") no-repeat 0 0;
		&:last-child {
			margin:0;
		}
	}
}
.about-project-block {
	.wrap-icon-cube {
		margin:20px 0 35px;
	}
}

.ab-text {
	padding:40px 0;
	p {
		margin:0 0 40px;
		font-weight:400;
	}
}



.wrap-news {
	padding: 60px 0 0;
}
.news {
	overflow: hidden;
	a {
		color: #000;
		display: block;
		&:hover {
			opacity:0.7;
		}
	}
	.item-news {
		display: inline-block;
		vertical-align: top;
		width:100%;
		margin:0 0 40px;
		p {
			padding:0 20px 0 0;
		}
	}
	.news-img {
		text-align: center;
		overflow: hidden;
		img {
			display: inline-block;
			vertical-align: middle;
			width:100% !important;
			height:auto !important;
		}
	}
	.wrap-b {
		padding:18px 0 0;
		margin:0 0 15px;
		.h2 {
			font-size:24px;
			margin:0 0 15px;
			//height:60px;
			overflow: hidden;
		}
		.date {
			font-size: 16px;
			color: #8892a0;
		}
	}
}

.articles {
	padding:50px 0 0;
	&:first-child{
		padding:0 !important;
	}
	&:nth-child(even) {
		background: #f4f5f8;
	}
	&:last-child {
		padding:50px 0 20px;
	}

}
.another-articles {
	.news {
		.item-news {
			border: none;
		}
	}
	.h2 {
		font-size: 37px;
		margin:0 0 40px;
	}
}
.news-block {
	margin:0 0 80px;
	p {
		line-height:26px;
		margin:0 0 30px;
	}
}

.wrap-one-news-img {
	text-align: center;
	img {
		margin:0 0 50px;
		max-height: 555px;
	}
}
.margin-bottom {
	margin-bottom: 20px;
}

.wrap-add-contacts {
	.h2 {
		font-size: 37px;
	}
	p {
		margin:0 0 20px;
	}
	.head_add {
		overflow: hidden;
		.reclama-link {
			float: right;
			font-size: 21px;
			color: #0d2556;
			text-decoration: underline;
			margin: 12px 0 0;
			&:hover {
				text-decoration: none;
			}
		}
		.h2 {
			float: left;
		}
	}
}
.wrap-tabs {
	margin-top: 40px;
}
.tab-box {
	margin:0 0 25px;
	padding: 0 35px;
	&:last-child {
		margin:0;
	}
}
.border {
	border-bottom: 2px solid #d2d9e5;
	margin:0 0 50px;
}
.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
	border: 2px solid #d2d9e5;
	border-radius: 0;
	border-bottom-color: transparent;
	font-weight:700;
	color: #000;
}
.nav-tabs {
	border-bottom: 2px solid #d2d9e5;
	li{
		margin-bottom: -2px;
		width:100%;
		max-width: 235px;
		text-align: center;
		a {
			color: #000;
			font-size: 21px;
			text-transform: uppercase;
			padding:20px 10px;
			margin: 0;
			&:hover {
				background-color: transparent;
				border-color: transparent;
				color: #6f6f6f;
			}
		}
	}
}

.tab-content {
	.my-container {
		border: 2px solid #d2d9e5;
		border-top: 0;
		padding:50px 0 0;
		margin:0 0 100px;
	}
	.t-head {
		font-size: 21px;
		margin:0 0 35px;
		font-weight:500;
	}
}
.wrap-input {
	margin:0 0 30px;
	&.relative-check {
		position: relative;
		.wrap-checkbox {
			position: absolute;
			top:0;
			left:0;
		}
		p {
			padding:0 0 0 50px;
		}
	}
	&.last-option {
		.custom-select-ul {
			li {
				&:last-child {
					padding: 10px 0 0;
				}
			}
		}
	}
	&.submit {
		text-align: center;
	}
	a {
		color: #000;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
	&:after {
		content: '';
		clear: both;
		display: block;
	}
	label {
		display: block;
		margin:0 0 20px;
		font-weight:400;
	}
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	textarea {
		width:100%;
		border: 2px solid #d2d9e5;
		padding:12px 20px;
		color: #000;
		&::placeholder {
			color: #a9b4c6;
		}
		&.with-ot {
			padding:12px 20px 12px 50px;
		}
		&:focus {
			border-color: #00b7e9;
		}
		&:focus + .ot {
			color: #000;
		}
	}
	input[type="submit"] {
		width:100%;
		height:100%;
		background: transparent;
		border: none;
		padding: 13px 48px;
	}
	.btn-b {
		padding:0;
		margin: 0 0 20px;
		.disabled {
			cursor: no-drop;
		}
		&.disabled {
			background: #a9b4c6;
			cursor: no-drop;
			&:after {
				background: #777f8c;
			}
			&:before {
				background: #919baa;
			}
		}
	}
	input[type="checkbox"] {
		display: none;
		&:checked  + .check .icon-check {
			display: block;
		}
	}
	.wrap-checkbox {
		float: left;
		cursor: pointer;
		margin:6px 25px 0 0;
	}
	.check {
		display: inline-block;
		vertical-align: top;
		border: 2px solid #d2d9e5;
		text-align: center;
		width:35px;
		height:34px;
		.icon-check {
			display: none;
			margin:5px auto 0;
			@include sprite($check);
		}
	}
	textarea {
		resize: none;
		height:160px;
	}
	.wrap-ot {
		position: relative;
		.ot {
			position: absolute;
			color: #a9b4c6;
			top: 0;
			left: 22px;
		}
	}
	.custom-select-wrapper {
		width:100%;
	}
	.custom-select-button {
		width:100%;
		padding:12px 20px;
		border: 2px solid #d2d9e5;
		height:50px;
		&:after {
			border-top-color: #d2d9e5;
		}
		&.custom-select-opened {
			border-color: #00b7e9;
			box-shadow: 2px 3px 9px rgba(0,0,0,0.23);
			&:after {
				border-bottom-color: #00b7e9;
			}
		}
	}
	.custom-select-ul {
		box-shadow: 2px 3px 9px rgba(0,0,0,0.23);
		li {
			margin:0 0 15px;
			&:last-child {
				margin:0;
			}
			&:first-child {
				margin:0;
			}
		}
	}
}

.img-tooltip {
	display: inline-block;
	vertical-align: middle;
	margin:0 0 0 10px;
	border: 0;
	background: transparent;
	cursor: pointer;
	@include sprite($head-tooltip)
}
.tooltip.in {
	opacity:1;
}
.tooltip-arrow {
	display: none;
}
.tooltip-inner {
	background: #fff;
	box-shadow: 0 0 9px rgba(0,0,0,0.23);
	border: 2px solid #d2d9e5;
	border-radius: 0;
	padding: 10px 20px 10px 20px;
	max-width:400px;
	text-align: left;
	p {
		font-size: 15px;
		color: #000;
		margin:0 0 3px;
		&:last-child {
			margin:0;
		}
	}
}


.form-group {

	.form-control {
		height:50px;
		box-shadow: inset 0 0 0 2px #d2d9e5;
		border-radius: 0;
		&.open {
			border-radius: 0;
			box-shadow: 2px 3px 9px rgba(0, 0, 0, 0.23), inset 0 0 0 2px #00b7e9 ;
			.caret {
				border-top: 0 !important;
				border-bottom: 8px solid #00b7e9;
			}
		}
	}
	.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
		margin:0 0 0 40px;
	}
	.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
		display: block;
		width:25px;
		height:25px;
		border: 2px solid #d2d9e5;
		display: block;
		left: 0;
		text-align: center;
		position: absolute;
		left:0;
		top:0;
		&:before {
			display: none;
		}
	}
	.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
		border-bottom: 0;
	}
	.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
		margin:0;
		&:after {
			display: block;
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -7px 0 0 -7px;
			@include sprite($check-small);
		}
		&:before {
			display: none;
		}
	}
	.bootstrap-select.btn-group .dropdown-toggle .caret {
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid #d2d9e5;
		position: absolute;
		right: 18px;
		top: 50%;
		margin: -4px 0 0;
	}
	.btn-group.open .dropdown-toggle {
		box-shadow: none;
	}
	.btn-default {
		padding:12px 20px;
		height:50px;
		border-radius: 0;
		border: none !important;
		background: transparent;
		&:hover, &:focus, &:active{
			background-color: transparent;
			box-shadow: none;
			border: none !important;
		}
	}
	.bootstrap-select .dropdown-toggle:focus {
		outline:none !important;
	}
	.btn-default.active,
	.btn-default:active,
	.open>.dropdown-toggle.btn-default {
		background-color: transparent;
		box-shadow: none;
	}
	.dropdown-menu {
		margin:0;
		border-radius: 0;
		box-shadow: 2px 3px 9px rgba(0,0,0,0.23);
		padding:25px 19px;
		border: 2px solid #00b7e9;
		border-top: 0;
	}
	.bootstrap-select.show-menu-arrow .dropdown-toggle:after,
	.bootstrap-select.show-menu-arrow .dropdown-toggle:before{
		display: none;
	}
	.bootstrap-select.btn-group .dropdown-toggle .filter-option {
		font-size: 17px;
	}
	.bootstrap-select.btn-group .dropdown-menu li{
		margin:0 0 15px;
		&:last-child {
			margin:0 0 5px;
		}
		a {
			padding:0;
			font-size: 17px;
			color: #000;
			&:hover {
				color: #555555;
				background: transparent;
			}
			&:focus, &:active {
				color: #00b7e9;
			}
		}
	}
}

.footer-bottom {
	background: #161616;
	padding:15px 0;
	p {
		font-size: 12px;
		color: #c2c2c2;
	}
}

.big-item-filter {
	padding:30px 0 0;
}

.wpar-clubs_2 {
	padding:0px 0 30px;
}
.wpar-clubs_3 {
	padding:80px 0 30px;
}
.big-item {
	background: #f4f5f8;
	padding:60px 0 20px;
	.h3 {
		font-size: 37px;
	}
	.g-item-img {
		height:455px;
		line-height: 450px;
	}
	& + .wpar-clubs_3 {
		padding-top: 80px;
	}
}

.big-item.c-big-item {
	.g-item_about {
		.h2 {
			height:27px;
		}
	}
	.adr {
		margin:0 0 20px;
	}
}
.wrapper-another-articles {
	background: #f4f5f8;
	padding:50px 0;
}

.name-clubs {
	padding:70px 0 60px;
	.c-title {
		font-size: 24px;
		font-weight: 500;
		color: #00b7e9;
		text-transform: uppercase;
		margin:0 0 25px;
	}
	.h1 {
		font-size: 50px;
		margin:0 0 30px;
	}
}
.info-list {
	background: url("../img/bg-2.jpg") repeat;
	text-align: center;
	padding:100px 0;
	margin:-180px 0 0;
	ul {
		li {
			max-width: 210px;
			color: #fff;
			font-size: 24px;
			margin: 0 auto 70px;
			&:last-child{
				margin:0 auto 0;
			}
			span {
				display: block;
			}
			img {
				margin:0 0 25px;
				max-width: 100%;
			}
		}
	}
}

.wrap-img-club {
	margin:0 0 50px;
}
.head {
	font-size: 35px;
	color: #0d2556;
	margin:0 0 35px;
	font-weight: 500;
}
.game-list {
	margin:0 0 40px;
	ul {
		font-size: 0;
		li {
			display: inline-block;
			vertical-align: top;
			width:33.33%;
			font-size: 16px;
			padding:0 15px 0 30px;
			position: relative;
			margin: 0 0 18px;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 2px;
				width:13px;
				height:13px;
				background: url("../img/svg/icons_romb_blue.svg") no-repeat 0 0;

			}
			a {
				color: #000000;
				&:hover {
					opacity: 0.7;
				}
			}
		}
	}
}

.prise {
	background: url("../img/bg-2.jpg") repeat;
	padding:55px 0 165px;
	.head {
		color: #00b7e9;
	}
	.delivery {
		color: #fff;
		text-align: left;
		max-width:570px;
		p {
			margin:0 0 20px;
		}
	}

	ul {
		column-count: 2;
		column-gap: 329px;
		li {
			max-width: 540px;
			width: 100%;
			display: list-item;
			vertical-align: top;
			color: #fff;
			margin:0 0 25px;
			position: relative;
			padding:0 0 0 30px;
			font-size: 0;
			&:before {
				content: '';
				position: absolute;
				left: 0;
				top: 4px;
				width:13px;
				height:13px;
				background: url("../img/svg/icons_romb_blue.svg") no-repeat 0 0;
			}
			span {
				font-size: 17px;
				display: inline-block;
				vertical-align: top;
				&:first-child {
					width:65%;
				}
				&:last-child {
					text-align: right;
					font-size: 24px;
					font-weight: 500;
					width: 34%;
				}
			}
		}
	}
	&.company-services-list {
		ul li{
			font-size: 17px;

			display: inline-block;
			vertical-align: top;
		}
	}
}
.vr-recomenduet {
	position: relative;
	background-size: cover;
	background-position: center top;
	height:540px;
	.container,.row, .col-xs-12 {
		height:100%;
	}
	.icon-rec {
		display: inline-block;
		vertical-align: bottom;
		position: absolute;
		bottom:45px;
		left:10px;
		width: 175px;
		height:175px;
		background: url("../img/svg/icons-VRDigest.svg") no-repeat 0 0;
	}
}
.contact-club {
	padding:65px 0 30px;
	.map {
		padding:0 8% 0 0;
		iframe {
			width:100%;
			height:440px;
		}
	}
}
.contacts-box {
	border: 2px solid #00b7e9;
	padding:60px 20px 30px 100px;
	margin:-165px 0 0;
	background: #fff;

}
.contact-club {
	.contacts-box {
		min-height: 609px;
	}
}
.contact-event {
	padding:0 0 70px;
	.contacts-box {
		border: 0;
		padding:0;
		margin:0;
		min-height: inherit;
	}
	.map-event {
		iframe {
			width:100%;
			height:420px;
		}
	}
}
.c-row {
	margin:0 0 29px;
	div, li{
		color: #000;
		margin:0 0 6px;
	}
	a {
		text-decoration: underline;
		color: #000;
		&:hover {
			text-decoration: none;
		}
	}
	.head-row {
		font-size: 15px;
		color: #cbd1dd;
		text-transform: uppercase;
		margin: 0 0 12px;
		font-weight: 700;
	}
	.adr {
		height:inherit;
	}
}
.reviews {
	font-size: 0;
}
.wrap-reviews {
	padding:30px 0 70px;
}
.reviews-box {
	width:33.33%;
	display: inline-block;
	vertical-align: top;
}
.reviews-content {
	margin:0 15px 35px 15px;
	display: inline-block;
	vertical-align: top;
	border: 2px solid #d2d9e5;
	position: relative;
	p {
		padding:20px 25px;
	}
	&:before {
		content: '';
		@include sprite($quotes);
		position: absolute;
		left: -18px;
		top:16px;
	}
	.name-rev {
		border-top: 2px solid #d2d9e5;
		font-size: 16px;
		color: #151f34;
		padding:10px 25px;
		font-weight: 700;
	}
}
.tovar-box {
	border: 2px solid #d2d9e5;
	margin:0 0 45px;
	.tovar-img {
		border-bottom: 2px solid #d2d9e5;
		height:230px;
		line-height: 230px;
		text-align: center;
		img {
			max-height: 100%;
			vertical-align: middle;
		}
	}
	.about-tovar {
		text-align: center;
		padding:20px 20px 25px;
	}
	.name-tovar {
		color: #151f34;
		font-weight: 500;
		height:43px;
		overflow: hidden;
		margin:0 0 20px;
		text-align: left;
	}
	.prise-tovar {
		font-size: 24px;
		color: #151f34;
		font-weight: 700;
		margin:0 0 15px;
	}
	.btn-b {
		width: 100%;
		max-width:210px;
	}
}

.list-offers ul {
	margin:0 0 0 40px;
	li {
		padding:0 0 0 25px;
		margin:0 0 15px;
		position: relative;
		&:before {
			content: '';
			position: absolute;
			left:0;
			top:4px;
			width:13px;
			height:13px;
			background: url("../img/svg/icons_romb_blue.svg") no-repeat 0 0;
		}
		a {
			color: #000;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
.add-img {
	p {
		font-size: 15px;
		font-weight: 500;
		margin: 0 0 10px;
	}
}

.news-block p {
	margin-bottom: 0;
}



.filter select {
	display: none;
}

.size-full {
	max-width: 100%;
	height: auto;
}

div.wpcf7 .ajax-loader {
	display: none!important;
}

input.wpcf7-submit:disabled {
	background: #a9b4c6;
	cursor: no-drop;
	opacity: 1;
	border-radius: 0;
	-webkit-appearance: button;
	-webkit-text-fill-color:#fff;

}

input.wpcf7-submit + span + .left-line {
	position: absolute;
	display: block;
	background: #0098c4;
	width: 6px;
	height: 100%;
	left: -6px;
	top: 0;
	-webkit-transform: skewY(-45deg);
	-ms-transform: skewY(-45deg);
	transform: skewY(-45deg);
	-webkit-transform-origin: 100% 100%;
	-ms-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
}

input.wpcf7-submit:disabled + span + .left-line {
	background: #919baa;
}

input.wpcf7-submit + span + .left-line + .bottom-line {
	position: absolute;
	display: block;
	background: #007a9f;
	width: 100%;
	height: 6px;
	left: -6px;
	bottom: -6px;
	-webkit-transform: skewX(-45deg);
	-ms-transform: skewX(-45deg);
	transform: skewX(-45deg);
	-webkit-transform-origin: 100% 100%;
	-ms-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
}

input.wpcf7-submit:disabled + span + .left-line + .bottom-line {
	background: #777f8c;
}

.wrap-input.submit .btn-b:before,
.wrap-input.submit .btn-b:after {
	display: none;
}

.wrap-input .wrap-ot {
	display: block;
}

.wrap-input .wrap-ot .ot {
	top: 14px;
}

.wrap-input label.file-label {
	display: inline-block;
	vertical-align: top;
	width:100%;
	max-width: 50%;
	height: 100px;
	line-height: 100px;
	background: #d4d8e4;
	text-align: center;
	cursor: pointer;
}

.wrap-input label.file-label input {
	display: none;
}

.input-file-img {
	width: 155px;
	height: 100px;
	background: no-repeat center;
	background-size: cover;
	float: left;
	margin-right: 10px;
}

.input-file-clear {
	display: none;
	cursor: pointer;
	color: #afafc7;
	text-decoration: underline;
}

.input-file-clear:hover {
	text-decoration: none;
}

.input-file-title {
	display: none;
	margin-bottom: 6px;
	font-weight: 700;
}

.input-file-info.active .input-file-clear,
.input-file-info.active .input-file-title {
	display: block;
}

.input-file-info {
	display: inline-block;
	vertical-align: top;
	width: 100%;
	max-width: 48%;
	margin-left: 6px;
}

.input-file-text {
	margin-bottom: 10px;
	height: 40px;
	overflow: hidden;
}

.wpcf7-form {
	position: relative;
}

.wpcf7-mail-sent-ok {
	text-align: center;
	margin: 0!important;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 9px;
	color: #00b7e9;
	border: none!important;
}

.wpcf7-validation-errors {
	text-align: center;
	margin: 0!important;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 9px;
	color: #f00;
	border: none!important;
}

.about-project .wrap-text p {
	margin-bottom: 0;
}

.filter {
	position: relative;
	display: inline-block;
}

.filter-preloader {
	position: absolute;
	top: 9px;
	right: -60px;
	width: 40px;
	display: none;
}

.wrapper-404 {
	padding: 30px 0 50px;
}

.text-404 {
	font-size: 180px;
	line-height: 180px;
	color: #00b7e9;
	font-weight: 700;
}

.desc-404 {
	font-size: 45px;
	margin-bottom: 30px;
}

.club-category-text {
	position: absolute!important;
	top: 0;
	left: 0;
	bottom:0;
	width: 90%;
	display: none;
}

.club-category-text.active {
	display: block;
	input {
		height:100%;
	}
}

.club-category-text.active-2 input {
	border-color: #00b7e9;
}

.selectoinput {
	position: relative;
}

.wrap-sl {
	margin:0 0 30px;
	text-align: center;
	p {
		font-size: 24px;
		font-weight: 600;
		line-height:26px;
	}
}

.big-item.big-item-no-bg {
	background: none;
	padding:0 0 30px;
}

.tovar-box .tovar-img {
	overflow: hidden;
}

.select-tab-btn {
	display: none;
}


//06.03.19
.wrapper-rec-articles {
	background: #f4f5f8;
	padding: 50px 0;
}

.rec-articles-title {

	.h2 {
		font-size: 37px;
		margin-bottom: 40px;
	}
}

.rec-article {
	border: 2px solid #d2d9e5;
	color: #000000;

	&-img {
		height: 297px;
		overflow: hidden;
		border-bottom: 2px solid #d2d9e5;
		text-align: center;
		line-height: 295px;
		position: relative;

		img {
			max-width: 100%;
			height: auto;
			display: inline-block;
			vertical-align: middle;
		}
	}

	&-about {
		padding: 20px 25px;
		display: flex;
		flex-direction: column;
	}

	&-title {
		margin-bottom: 10px;
		height: 52px;
		overflow: hidden;

		.h2 {
			font-size: 24px;
			margin: 0;
			color: #0d2556;
			font-weight: 700;
		}
	}

	&-city {
		margin-bottom: 15px;
	}

	&-short {
		height: 110px;
		overflow: hidden;
	}

	&-footer {
		border-top: 2px solid #d2d9e5;
		padding: 15px 25px;
		color: #151f34;
		font-size: 17px;
		font-weight: 700;
		text-transform: uppercase;

		> div {
			height: 68px;
			line-height: 68px;
			overflow: hidden;
		}

		span {
			display: inline-block;
			vertical-align: top;
			line-height: 1.3;
		}
	}
}

.rec-article-link {
	display: block;
	position: relative;

	&:hover {
		&:before {
			content: '';
			position: absolute;
			display: block;
			background: #0098c4;
			width: 6px;
			height: 100%;
			left: -6px;
			top: 0;
			transform: skewY(-45deg);
			transform-origin: 100% 100%;
		}
		&:after {
			content: '';
			position: absolute;
			display: block;
			background: #007a9f;
			width: 100%;
			height: 6px;
			left: -6px;
			bottom: -6px;
			transform: skewX(-45deg);
			transform-origin: 100% 100%;
		}

		.hover-block {
			display: block;
		}

		.rec-article-about, .rec-article-footer > div {
			position: relative;
			z-index: 2;
			color: #fff;
		}

		.rec-article-title .h2 {
			color: #fff;
		}
	}

	.hover-block {
		display: none;
		line-height: 1.3;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		padding: 30px 20px;
		color: #fff;
		text-align: left;
		background: rgba(0, 183, 233, 0.9);
	}
}


.bacground-white {
	background: #fff;
}

.franchise-box {
	.g-item_about .h2 {
		height: 54px;
	}

	&-text {
		height: 88px;
		overflow: hidden;
	}

	.vr {
		span {
			display: block;
			height: 20px;
			overflow: hidden;
		}
	}
}

.color-white {
	color: #fff;
}

.video-box-wrapper {
	background: #f4f5f8;
	padding: 80px 0;
}

.youtube-video-box {
	width: 100%;
	max-width: 930px;
	margin: 0 auto;

	&-container {
		position: relative;
		padding: 28.1%;
	}

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.club-filter {
	display: block;
	min-height: 50px;
	margin-bottom: 30px;

	select {
		opacity: 0;
	}

	.custom-select-wrapper {
		display: block;
	}

	.custom-select-button {
		width: 100%;
		position: relative;
		height: 50px;

		.custom-search-input {
			position: absolute;
			top: 0;
			left: 0;
			right: 40px;
			bottom: 0;
			border: none;
			background: none;
			padding-left: 19px;
		}

		span {
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.custom-select-ul li:last-child {
		margin-bottom: 0;
	}
	.form-control {
		box-shadow: inset 0 0 0 2px #00b7e9;
	}

	.bootstrap-select.btn-group .dropdown-toggle .caret {
		border-top-color: #00b7e9;
	}

	.bs-placeholder.btn-default {
		color: #000!important;
	}

	.dropdown-menu>.active>a {
		background: none;
	}

	&.form-group .btn-default {
		padding: 12px 40px 12px 20px!important;
	}

	.notify {
		background: none!important;
		margin: 0!important;
		bottom: 0!important;
		border: none!important;
		box-shadow: none!important;
	}

	.no-results {
		background: none!important;
		white-space: normal!important;
		overflow: hidden;
	}

	div.dropdown-menu {
		width: 100%;
	}

	.dropdown-menu.inner {
		max-height: 200px!important;
	}
}

.g-item_main-about {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	line-height: 1.3;
	text-align: right;
	padding: 30px 20px;
	color: #fff;

	&-row {
		margin: 0 0 4px;
	}

	&-bg {
		background: rgba(#151f34, 0.75);
		display: inline-block;
		vertical-align: top;
		padding: 6px 55px 6px 10px;
		position: relative;
	}

	i {
		display: inline-block;
		vertical-align: middle;
		margin: auto;
		position: absolute;
		top: 0;
		right: 10px;
		bottom: 0;
	}

	i.icon-R {
		background-image: url(../sprites/sprite.png);
		background-position: 0px -181px;
		width: 27px;
		height: 24px;
	}

	i.icon-user {
		background-image: url(../sprites/sprite.png);
		background-position: 0px -239px;
		width: 24px;
		height: 24px;
	}

	i.icon-plastic {
		background: url('../img/icon-small-white-1.png') no-repeat center;
		width: 27px;
		height: 20px;
	}

	i.icon-relax {
		background: url('../img/icon-small-white-2.png') no-repeat center;
		width: 33px;
		height: 19px;
	}
}

.hover-block_top {

	> div {
		padding: 6px 55px 6px 10px;
		position: relative;
		margin: 0 0 4px;
	}

	i {
		display: inline-block;
		vertical-align: middle;
		margin: auto;
		position: absolute;
		top: 0;
		right: 10px;
		bottom: 0;
	}

	i.icon-plastic {
		background: url('../img/icon-small-white-1.png') no-repeat center;
		width: 27px;
		height: 20px;
	}

	i.icon-relax {
		background: url('../img/icon-small-white-2.png') no-repeat center;
		width: 33px;
		height: 19px;
	}
}

.g-item-link:hover {
	.g-item_main-about {
		display: none;
	}
	.g-item_about-club-price {
		color: #fff;
	}
}

.g-item_about-club-price {
	font-size: 18px;
	line-height: 26px;
	font-weight: 700;
	color: #0d2556;
	margin-bottom: 8px;
	height: 26px;
	overflow: hidden;
}

.about-club_contacts {
	p {
		padding-bottom: 5px;
	}

	.adr {
		height: auto;
	}

	> div:last-child {
		p {
			padding-bottom: 0;
		}
	}
}

.icon-relax-blue {
	background: url("../img/icon-small-blue-2.png") no-repeat center left;
	width: 33px;
	height: 19px;
}

.info-cl div i {
	width: 34px!important;
	background-repeat: no-repeat;
}

.head-with-btn {
	padding-right: 230px;
	position: relative;
}

.feedback-btn {
	position: absolute;
	top: 0;
	right: 0;
	background: #00b7e9;
	color: #fff;
	font-size: 19px;
	font-weight: 500;
	width: 210px;
	height: 52px;
	border: none;

	&:hover {
		background: #62ccf5;
	}
}

//modal
.modal-dialog {
	max-width: 690px;
}

.modal-content {
	box-shadow: none;
	border-radius: 0;
	border: 2px solid #d2d9e5;
	position: relative;
}

.modal-body {
	padding: 50px 43px 35px;

	.div.wpcf7-response-output, .wpcf7-validation-errors, .wpcf7-mail-sent-ok {
		position: static;
		padding: 0 15px;
	}
}

.modal-title {
	text-align: center;
	font-size: 21px;
	font-weight: 500;
	margin-bottom: 25px;
}

.wrap-submit {
	text-align: center;
	margin-bottom: 0;
}

#feedback-modal {
	.close {
		position: absolute;
		z-index: 1;
		width: 26px;
		height: 26px;
		opacity: 1!important;
		top: 20px;
		right: 20px;
		font-size: 40px;
		line-height: 26px;
		color: #d2d9e5;

		&:hover {
			color: #00b7e9;
		}
	}
}

.hidden-accept {
	display: none!important;
}
//modal

.bs-searchbox {
	padding: 0;
	margin: -25px -19px 19px -19px;
	position: relative;

	.form-control {
		box-shadow: none;
		border: none;
		border-bottom: 2px solid #d2d9e5;
		padding: 0 19px;
	}
}

.clear-select {
	width: 100%;
	height: 32px;
	line-height: 32px;
	font-size: 17px;
	background: #d2d9e5;
	text-align: center;
	color: #000;
	cursor: pointer;
	margin-top: 23px;

	&:hover {
		opacity: 0.8;
	}
}

#filter-button {
	font-size: 17px;
	font-weight: 500;
	color: #fff;
	text-align: center;
	width: 210px;
	height: 50px;
	background: #00b7e9;
	border: none;

	&:hover {
		background: #62ccf5;
	}
}

.filter-2-buttons {
	font-size: 0;

	button {
		width: 210px;
		height: 48px;
		background: #fff;
		border: 2px solid #d2d9e5;
		font-size: 17px;
		text-align: center;
		margin-right: 30px;
		margin-bottom: 30px;
		padding: 0;

		&.active {
			background: #d2d9e5;
		}
	}
}

.hidden-filter-element {
	display: none!important;
}

.filter-preloader {
	top: 5px;
	left: 240px;
	right: inherit;
}

.filter-preloader-2 {
	position: absolute;
	margin-top: 3px;
	display: none;
	width: 40px;

	img {
		width: 100%;
	}
}

.no-padding {
	padding: 0!important;
}

.clubs-top-row {
	padding: 80px 0 30px;
}

.no-touch {
	.filter-2-buttons {

		button {

			&:hover {
				background: #d2d9e5;
			}
		}
	}
}

.clear-search-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	background: none;
	font-size: 32px;
	color: #d2d9e5;
	border: none;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;

	&.active {
		opacity: 1;
		visibility: visible;
	}
}

.bs-searchbox .form-control {
	padding-right: 50px;
}

@media screen and (max-width: 1440px) {
	.g-item-img {
		height: 250px;
		line-height: 240px;
	}
	.g-item {
		margin: 0 0 45px;
	}
	.big-item .g-item-img {
		height: 385px;
		line-height: 375px;
	}
	.list-offers ul{
		margin:0 0 0 30px;
		li {
			font-size: 16px;
		}
	}
	.text-p {
		font-size: 16px;
	}
	.wrap-email-contacts {
		padding:12px 10px;
		a {
			font-size: 22px;
		}
	}
	.wrap-add-contacts {
		p {
			font-size: 16px;
		}
		.h2 {
			font-size: 35px;
		}
		.head_add .reclama-link {
			font-size: 20px;
		}
	}
	.nav-tabs li a {
		font-size: 20px;
	}
	.margin-bottom {
		margin-bottom: 10px;
	}
	.tovar-box {
		.name-tovar {
			font-size: 16px;
		}
		.prise-tovar {
			font-size: 22px;
		}
	}
	.prise .delivery p {
		font-size: 16px;
	}
	.vr-recomenduet .icon-rec {
		height:150px;
		width:150px;
	}
	.name-clubs {
		.c-title {
			font-size: 22px;
		}
		.h1 {
			font-size: 47px;
		}
		p {
			font-size: 16px;
		}
	}
	.info-list ul li {
		font-size: 22px;
	}
	.game-list ul li {
		font-size: 16px;
	}
	.prise ul li span{
		font-size: 16px;
	}
	.prise ul li span:last-child {
		font-size: 22px;
	}
	.prise.company-services-list {
		ul li{
			font-size: 16px;
		}
	}
	.c-row div, .c-row li {
		font-size: 16px;
	}
	.reviews-content p {
		font-size: 16px;
	}
	.contact-club .map iframe {
		height:420px;
	}
	.sub-menu li a {
		font-size: 20px;
	}
	.wrap-sl .sl, .content-box blockquote p {
		font-size: 22px;
	}

	.about-project-block p, .ab-text p {
		font-size: 16px;
	}
	.content-box ul li {
		font-size: 16px;
	}
	.wrap-news {
		padding:40px 0 0;
	}
	.news {
		.wrap-b {
			padding:20px 0 0;
			margin:0 0 15px;
			.h2 {
				font-size: 22px;
				//height:48px;
			}
			.date {
				font-size: 15px;
			}
		}
		.item-news p {
			font-size: 16px;
		}
	}
	.big-item.c-big-item {
		.g-item_about .h2 {
			font-size: 22px;
		}
		.adr, p {
			font-size: 16px;
		}
		.g-item .vr {
			font-size: 16px;
		}
	}
	.c-item-link {
		margin:0 0 45px;
	}
	.c-item-about {
		.h2 {
			font-size: 22px;
		}
		.adr {
			font-size: 16px;
			margin: 0 0 55px;
		}
		.vr {
			font-size: 16px;
		}
	}
	.c-item-about-top p {
		font-size: 16px;
	}
	.wrap-h1 {
		.h1 {
			font-size: 47px;
		}
	}
	.shop-page {
		padding:70px 0 20px;
		.h2 {
			font-size: 35px;
			margin:0 0 15px;
		}
		.case {
			p {
				font-size: 16px;
			}
			a {
				padding: 40px 25px 20px;
				min-height: 170px;
			}
		}
	}
	.logo p {
		font-size: 17px;
	}
	.menu-top ul li {
		margin: 0 0 0 38px;
	}
	.wrap-email{
		margin:0 0 0 60px;
	}
	.container {
		padding-right: 10px;
		padding-left: 10px;
	}
	.row {
		margin-right: -10px;
		margin-left: -10px;
	}
	.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
		padding-right: 10px;
		padding-left: 10px;
	}
	.case p {
		font-size: 15px;
	}
	.about-project .wrap-text p {
		font-size: 16px;
	}
	.h1 {
		font-size: 90px;
	}
	.nav-l-footer li {
		margin:0 20px 0 0;
		a {
			font-size: 14px;
		}
	}
	.nav-r-footer li {
		margin:0 30px 0 0;
		a {
			font-size: 17px;
		}
	}
	.logo_made .made span {
		font-size: 14px;
	}
	.h2 {
		font-size: 40px;
	}

	//06.03.19
	.filter-2-buttons button {
		width: 180px;
		margin-right: 20px;
	}

	#filter-button {
		width: 180px;
	}

	.filter-preloader {
		left: 210px;
	}

	.feedback-btn {
		font-size: 16px;
		width: 180px;
		height: 50px;
	}

	.rec-article-img {
		height: 250px;
		line-height: 250px;
	}

	.rec-articles-title .h2 {
		font-size: 35px;
	}

	.rec-article-title .h2 {
		font-size: 22px;
	}

	.rec-article-title {
		height: 48px;
	}

	.rec-article-city, .rec-article-short, .rec-article-footer {
		font-size: 16px;
	}

	.rec-article-short {
		height: 100px;
	}

	.rec-article-footer > div {
		height: 60px;
		line-height: 60px;
	}

	.social a {
		width: 41px;
		height: 41px;
	}
}

@media screen and (max-width: 1200px) {
	.border {
		margin:0 0 47px;
	}
	.reviews-content {
		margin: 0 15px 30px 15px;
	}
	.big-item {
		padding: 60px 0 35px;
	}
	.g-item {
		margin: 0 0 45px;
	}
	.sub-menu li a:active {
		color: #151f34;
	}
	.input-file-info.active .input-file-clear, .input-file-info.active .input-file-title {
		font-size: 15px;
	}
	.vr-recomenduet {
		height:445px;
	}
	.input-file-img {
		display: none !important;
	}
	.input-file-info {
		width: 100% !important;
		margin-left:0 !important;
		&.active {
			.input-file-img {
				display: inline-block !important;
			}
		}
	}
	.input-file-text {
		height:33px;
		font-size: 13px;
	}

	.wrap-email-contacts {
		padding:16px 10px;
		margin: 0;
		a {
			font-size: 20px;
		}
	}
	.wrap-add-contacts {
		p {
			font-size: 14px;
		}
		.h2 {
			font-size: 31px;
		}
		.head_add .reclama-link {
			font-size: 18px;
		}
	}
	.add-img p {
		font-size: 13px!important;
	}
	.nav-tabs li {
		max-width: 25%;
		a {
			font-size: 19px;
			padding:14px 10px;
		}
	}
	.tab-box {
		padding:0 10px;
	}
	.tab-content .t-head {
		font-size: 19px;
		margin: 0 0 25px
	}
	.wrap-input {
		margin:0 0 20px;
		label {
			font-size: 14px;
			margin: 0 0 10px;
		}

		.wrap-checkbox {
			margin:0 25px 0 0;
		}
	}
	.wrap-input input[type="text"], .wrap-input input[type="email"], .wrap-input input[type="tel"], .wrap-input textarea {
		font-size: 14px;
	}
	.form-group .bootstrap-select.btn-group .dropdown-menu li a ,
	.form-group .bootstrap-select.btn-group .dropdown-toggle .filter-option{
		font-size: 14px;
	}
	.vr-recomenduet .icon-rec {
		height:108px;
		width:108px;
		bottom:30px;
	}
	.name-clubs {
		padding:45px 0;
		.c-title {
			font-size: 20px;
		}
		.h1 {
			font-size: 42px;
		}
		p {
			font-size: 14px;
		}
	}
	.info-list {
			padding: 70px 0;
			margin: -120px 0 0;
		ul li {
			font-size: 19px;
			margin:0 auto 50px;
			padding:0 15px;

		}
	}
	.head {
		font-size: 31px;
		margin: 0 0 30px;
	}
	.game-list {
		margin:0 0 30px;
		ul li {
			font-size: 14px;
			margin:0 0 15px;
		}

	}
	.prise {
		padding:42px 0 115px;
		ul {
			column-gap: 220px;
			li {
				&:before {
					top: 2px;
				}
				span{
					font-size: 14px;
				}
			}

		}
	}

	.prise ul li span:last-child {
		font-size: 18px;
	}
	.prise.company-services-list {
		ul li{
			font-size: 14px;
		}
	}
	.c-row div, .c-row li {
		font-size: 14px;
	}
	.reviews-content p {
		font-size: 14px;
	}
	.c-row .head-row {
		font-size: 13px;
	}
	.reviews-content .name-rev {
		font-size: 14px;
	}
	.contact-club {
		padding:45px 0 20px;
		.map {
			padding:0;
			iframe {
				height: 420px;
			}
		}
	}

	.contact-club {
		.contacts-box {
			min-height: 535px;
			margin: -115px 0 0;
			padding:45px 20px 22px;
		}
	}
	.wrap-img-club {
		margin:0 0 40px;
	}
	.wrap-reviews {
		padding:20px 0 55px;
	}
	.sub-menu {
		padding:25px 20px;
		li {
			margin: 0 0 30px;
			a {
				font-size: 18px;
			}
		}

	}
	.wrapper-another-articles {
		padding:40px 0;
	}

	.news-block p {
		font-size: 16px;
		.content-box {
			font-size: 16px;
			blockquote p {
				font-size: 22px;
			}
		}
	}
	.wrap-one-news-img img {
		margin:0 0 35px;
	}
	.content-box{
		blockquote {
			margin:30px 0;
		}
	}
	.wrap-icon-cube {
		margin:25px 0;
	}
	.about-project-block {
		padding:40px 0 0;
	}
	.about-project-block p, .ab-text p {
		font-size: 14px;
		line-height: 18px;
	}
	.content-box ul {
		margin: 20px 0 10px 20px;
		li {
			font-size: 14px;
			line-height: 18px;
			&:before {
				top:3px;
			}
		}
	}
	.wrap-sl .sl, .content-box blockquote p{
		font-size: 20px;
	}

	.ab-text {
		padding:30px 0 10px;
	}
	.info-project {
		margin:0 0 20px;
	}
	.big-item.c-big-item {
		.g-item_about .h2 {
			font-size: 20px;
		}
		.adr, p {
			font-size: 14px;
		}
		.g-item .vr {
			font-size: 14px;
			padding:10px;
		}
	}
	.g-item_about .g-item_about-top {
		padding: 15px 10px;
	}
	.c-item-link {
		margin:0 0 35px;
	}
	.c-item-about {
		.h2 {
			font-size: 20px;
		}
		.adr {
			font-size: 14px;
			margin: 0 0 15px;
		}
		.vr {
			font-size: 14px;
		}
	}
	.c-item-about-top p {
		font-size: 14px;
	}
	.wrap-h1 .h1 {
		font-size: 42px;
		margin: 0 0 30px;
	}
	.text-p {
		font-size: 14px;
		margin: 0 0 20px;
	}
	.g-item-img {
		height:197px;
		line-height: 180px;
	}
	.big-item .g-item-img {
		height: 304px;
		line-height: 290px;
	}
	.wrap-img-podrobno {
		img {
			height:auto !important;
		}
	}
	.club .h1 {
		font-size: 47px;
	}
	.g-item .vr {
		height: 55px;
	}
	.g-item_about .h2 {
		height:54px;
	}
	.g-item-img .hover-block p,
	.hover-block_top > div{
		font-size: 14px;
	}
	.g-item_about .h2 {
		height:52px;
	}
	.right-block .menu-top {
		display: none;
	}
	.logo p {
		font-size: 16px;
	}
	.wrap-email a {
		font-size: 19px;
	}
	.h1 {
		font-size:57px;
	}
	.wrap-home-block .home-block {
		margin: 20px 0px 20px;
		min-height: 360px;
		padding: 55px 13% 0 64px;
	}
	.right-block .mob-menu {
		display: inline-block;
	}
	.h2 {
		font-size: 38px;
	}
	.another-articles .h2 {
		font-size: 35px;
		margin: 0 0 35px;
	}
	.nav-menu .menu > li > a {
		font-size: 18px;
	}
	.case {
		a {
			padding: 30px 15px 20px;
			min-height: 200px;
		}
		.wrap-img {
			img {
				height:140px;
			}
		}
		p {
			height:80px;
		}
	}
	.h3 {
		font-size: 42px;
	}
	.big-item .h3 {
		font-size: 35px;
	}
	.logo_made {
		.wrap-logo-f {
			display: block;
			width:100%;
			margin:0 0 70px;
		}
		.made {
			width:100%;
			display: block;
			text-align: left;
		}
	}
	#footer {
		.f-left {
			padding:20px 0 35px;
		}
		.f-right {
			padding:40px 0 30px;
			.margin {
				margin-bottom:55px;
			}
		}
	}


	//06.03.19
	.g-item_main-about {
		font-size: 14px;
	}

	.g-item_main-about i, .g-item-img .hover-block i {
		right: 7px;
	}

	.g-item_main-about-bg, .hover-block_top > div {
		padding: 6px 50px 6px 10px;
	}

	.rec-article-img {
		height: 202px;
		line-height: 200px;
	}
}
@media screen and (max-width: 992px) {
	.game-list {
		margin: 0 0 25px;
	}
	.about-project-block {
		padding:30px 0 0;
	}
	.f-block-img img {
		max-height: 133px;
	}
	.about-club_contacts, .info-cl {
		div {
			font-size: 14px;
		}
	}
	.case {
		.wrap-img {
			margin:0 0 20px;
		}
	}
	.border {
		margin: 0px 0 35px;
	}
	.another-articles .h2 {
		font-size: 31px;
		margin: 0 0 25px;
	}
	.about-project-block {
		.wrap-icon-cube {
			margin:15px 0 30px;
		}
	}

	.tovar-box {
		margin:0 0 25px;
	}
	.reviews-content {
		margin: 0 15px 20px 15px;
	}
	.big-item .h3 {
		font-size: 31px;
		margin:0 0 25px;
	}
	.case {
		margin: 0 0 50px 10px;
		.h2 {
			margin: 0 0 15px;
		}
	}
	.h3 {
		margin:0 0 30px;
	}
	.wrap-email {
		margin: 0 0 0 20px;
	}
	.about-project .wrap-text {
		margin: 0 0 30px;
		p {
			font-size: 14px;
		}
	}
	.about-project {
		padding: 60px 0 80px;
	}
	.text-404 {
		font-size: 80px;
		line-height:80px;
	}
	.desc-404 {
		font-size: 20px;
		margin-bottom: 25px;
	}

	.list-offers ul{
		margin:0 0 0 20px;
		li {
			font-size: 14px;
		}

	}
	.contact-event {
		padding:0 0 40px;
	}
	.tovar-box {
		.name-tovar {
			font-size: 14px;
			height:35px;
		}
		.prise-tovar {
			font-size: 19px;
		}
		.tovar-img {
			height:150px;
			line-height: 130px;
		}
		.about-tovar {
			padding:10px 10px 25px;
		}
		.btn-b {
			max-width:180px;
		}
	}
	.prise {
		.delivery p {
			font-size: 14px;
		}
		ul {
			column-gap: 20px;
		}
	}
	.game-list ul li {
		width:50%;
	}
	.reviews-box {
		width: 50%;
	}
	.header_bottom.fixed {
		.wrap-logo {
			display: none;
		}
		.wrap-nav {
			width:100%;
		}
	}
	.news-block {
		margin:0 0 60px;
		p {
			font-size: 14px;
			line-height: 18px;
			.content-box {
				font-size: 14px;
				line-height: 18px;
				blockquote p {
					font-size: 20px;
				}
			}
		}
	}
	.content-box blockquote {
		margin: 15px 0;
	}
	.wrap-news {
		padding:30px 0 0;
	}
	.news {
		.wrap-b {
			padding:15px 0 0;
			margin:0 0 15px;
			.h2 {
				font-size: 18px;
				//height:39px;
				margin:0 0 10px;
			}
			.date {
				font-size: 13px;
			}
		}
		.item-news {
			margin:0 0 35px;
			p {
				font-size: 13px;
			}
		}
	}
	.shop-page {
		padding: 50px 0 30px;
		.case {
			margin: 0 0 20px;
			a {
				padding:24px 15px 20px;
				min-height: 140px;
				.h2 {
					margin:0 0 15px;
					font-size: 29px;
				}
			}
			p {
				font-size: 13px;
				height:49px;
			}
		}
	}
	.big-item .g-item-img {
		height: 231px;
		line-height: 220px;
	}
	.wrap-cases {
		padding: 65px 0 25px;
	}
	.club {
		padding:10px 0 15px;
	}
	.about-club .vr {
		font-size: 14px;
		margin: 0 0 25px;
		padding:0 0 20px;
	}
	.info-cl {
		margin:0 0 25px;
	}
	.about-club_contacts {
		margin:0 0 25px;
		padding:0 0 25px;
	}
	.about-club .text p {
		font-size: 14px;
		line-height: 18px;
	}
	.breadcrumbs {
		margin:40px 0 0px;
		li {
			font-size: 14px;
		}
	}
	.about-club_contacts .phone, .about-club_contacts .email{
		margin:0 0 20px;
		font-size: 14px;
	}
	.club .h1 {
		font-size: 42px;
		margin:0 0 20px;
		padding: 0 0 20px;
	}
	.wpar-clubs_2 {
		padding:0px 0 20px;
	}
	.wpar-clubs_3 {
		padding:60px 0 20px;
	}
	.big-item {

		& + .wpar-clubs_3 {
			padding-top: 60px;
		}
	}
	.filter .filter-head {
		font-size: 15px;
	}
	.custom-select-button {
		font-size: 15px;
	}
	.custom-select-ul li {
		font-size: 15px;
	}
	.g-item_about .h2 {
		font-size: 20px;
		height:47px;
	}
	.adr {
		font-size: 14px;
		.cube {
			margin: 1px 10px 0 0;
		}
	}
	.wpar-all-items {
		padding:20px 0;
	}
	.wrap-company-item {
		padding:60px 0 40px;
	}
	.filter {
		margin:0 0 20px;
	}
	.big-item-filter {
		padding: 20px 0 0;
	}
	.big-item {
		padding:40px 0 20px;
	}
	.g-item {
		margin:0 0 35px;
		.vr {
			font-size: 14px;
			height:52px;
			&.dop-class {
				height: 70px;
			}
		}
	}
	.first-block {
		.h1 {
			font-size: 42px;
		}
		p {
			font-size: 14px;
		}
	}
	.f-block-img {
		padding: 0 8%;
	}
	.social {
		padding:0;
		a {
			width:44px;
			height:44px;
		}
	}
	.copyright {
		font-size: 13px;
	}
	.btn-b {
		font-size: 18px;
		padding:11px 53px;
	}
	.bg-gray {
		padding:60px 0 80px;
	}
	.post {
		margin:0 0 40px;
		.h3 {
			font-size: 20px;
			height:45px;
			margin:0 0 15px;
		}
		.post_wrap-img {
			margin:0 0 15px;
		}
		.post-date {
			font-size: 14px;
		}
		p {
			font-size: 14px;
		}
	}
	.shop-page {
		padding: 50px 0 40px;
		.case {
			margin: 0 0 20px 10px;
			a {
				padding:24px 15px 20px;
				min-height: 140px;
				.h2 {
					margin:0 0 15px;
					font-size: 29px;
				}
			}
			p {
				font-size: 13px;
				height:49px;
			}
		}
	}

	//06.03.19
	.g-item_main-about, .g-item-img .hover-block {
		padding: 10px 15px;
	}

	.club-filter {
		margin-bottom: 20px;
	}

	.club-filter .custom-select-button {
		line-height: 22px;
	}

	.club-filter .filter-option.pull-left,
	.club-filter .bs-searchbox,
	.club-filter .dropdown-menu.inner,
	.club-filter li .text {
		font-size: 15px!important;
	}

	.club-filter li .text {
		line-height: 23px!important;
	}

	.club-filter .check-mark {
		width: 23px!important;
		height: 23px!important;
	}

	.clear-select {
		font-size: 15px;
	}

	.club-filter .bs-searchbox input {
		height: 42px!important;
	}

	.filter-2-buttons {
		margin: 5px -10px;

		button {
			margin: 0 10px 20px;
			width: calc(25% - 20px);
			font-size: 15px;
		}
	}

	.filter-preloader-2 {
		margin-top: 0;
		right: 10px;
		top: -65px;
	}

	.filter-preloader {
		left: inherit;
		right: 10px;
	}

	.clubs-top-row {
		padding: 60px 0 20px;
	}

	.feedback-btn {
		font-size: 15px;
		width: 180px;
		height: 50px;
	}

	.modal-title {
		font-size: 19px;
	}

	.modal-body {
		padding: 50px 10px 35px;
	}

	#feedback-modal .close {
		top: 7px;
		right: 7px;
	}

	.rec-article-link {
		margin-bottom: 35px;
	}

	.wrapper-rec-articles {
		padding: 40px 0 5px;
	}

	.rec-article-img {
		height: 236px;
		line-height: 230px;
	}

	.rec-article-title .h2 {
		font-size: 20px;
	}

	.rec-article-title {
		height: 44px;
	}

	.rec-article-city {
		font-size: 14px;
		font-weight: 900;
		margin-bottom: 10px;
	}

	.rec-article-short {
		font-size: 14px;
	}

	.rec-article-about {
		padding: 12px 10px;
	}

	.rec-article-footer {
		padding: 10px;
		font-size: 14px;
	}

	.rec-article-footer > div {
		height: 36px;
		line-height: 36px;
	}

	.rec-article-short {
		height: 90px;
	}

	.rec-articles-title .h2 {
		font-size: 31px;
	}

	#footer .f-left:after {
		content: '';
		display: block;
		position: absolute;
		left: 100%;
		top: 0;
		width: 5px;
		height: 100%;
		background: #00b7e9;
	}

	.social {
		font-size: 0;
	}

}
@media screen and (min-width: 767px) {

	.wrap-tabs .nav-tabs {
		display: block!important;
	}

}
@media screen and (max-width: 767px) {
	.game-list {
		margin: 0 0 20px;
	}
	.about-club .text > div {
		font-size: 14px;
	}
	.input-file-img {
		height:54px;
		width: 100%;
		max-width:155px;
	}
	.input-file-text {
		display: none;
	}
	.input-file-info.active .input-file-title {
		display: none;
	}
	.input-file-info.active .input-file-clear {
		margin: 0;
	}
	.input-file-info {
		position: relative;
		padding:0 0 9px 10px;
	}
	.input-file-clear {
		position: absolute;
		top: 100%;
	}
	.tab-box {
		padding: 0 10px;
		margin: 0 0 15px;
	}
	.border {
		margin: 0 0 30px;
	}
	.about-project-block {
		.wrap-icon-cube {
			margin:5px 0 20px;
		}
	}
	.first-block .h1 {
		font-size: 30px;
	}
	.g-item {
		margin: 0 0 30px;
	}
		#footer .f-right .made {
		margin: 14px 0 0;
	}
	.big-item {
		padding: 35px 0 15px;
		.h3 {
			font-size: 23px;
		}
	}
	.case .h2 {
		margin: 0 0 10px;
	}
	.tab-content .t-head {
		font-size: 16px;
		margin: 0 0 20px;
	}
	.mob-caret {
		position: absolute;
		top: 0;
		right: 0;
		height: 64px;
		width: 50px;
		z-index: 1;
	}
	.tabs-header {
		position: relative;
	}
	.select-tab-btn {
		display: block;
		border: 2px solid #d2d9e5;
		padding: 9px 10px;
		font-size: 16px;
		text-transform: uppercase;
		position: relative;
	}
	.select-tab-btn:before {
		content: '';
		position: absolute;
		right: 10px;
		top: 50%;
		margin: -3px 0 0;
		width: 0;
		height: 0;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-top: 7px solid #a9b4c6;
	}
	.select-tab-btn.active:before {
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-bottom: 7px solid #02b7e9;
		border-top: none;
	}
	.select-tab-btn.active {
		border-color: #00b7e9;
	}
	.wrap-tabs .nav-tabs {
		display: none;
		text-align: left;
		padding: 25px 10px;
		border: 2px solid #00b7e9;
		border-top: none;
		position: absolute;
		background: #fff;
		z-index: 2;
		top: 100%;
		right: 0;
		left: 0;
	}

	.wrap-tabs .nav-tabs li {
		width: 100%;
		max-width: inherit;
		text-align: left;
		margin: 0 0 25px;
	}
	.wrap-tabs .nav-tabs li:last-child {
		margin: 0;
	}

	.wrap-tabs .nav-tabs li a {
		border: none;
		padding:0;
		font-size: 16px;
	}

	.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
		border: none;
	}
	.tab-content .my-container {
		padding:35px 0 0;
	}
	.mob-list-1 .menu > li {
		padding: 13px 10px;
	}
	.mob-list-1 .menu > li.menu-item-has-children:before {
		top: 20px;
	}
	.mob-list li {
		margin: 0 0 25px;
	}
	header {
		border-bottom: 2px solid #d2d9e5;
	}
	.category-clubs, .category-shops, .category-companies, .category-events, .home{
		header {
			border-bottom: none;
		}
	}
	.form-group .dropdown-menu {
		padding:15px 10px;
	}
	.form-group .bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
		width: 20px;
		height:20px;
	}
	.form-group .bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
		margin: 0 0 0 30px;
	}
	.list-offers ul{
		margin:0 0 0 10px;
		li {
			margin: 0 0 10px;
		}

	}
	.wrap-input .wrap-ot .ot {
		font-size: 14px;
	}
	.wrap-input label.file-label {
		padding:30px 20px;
		line-height: inherit;
		height:inherit;
	}
	.vr-recomenduet {
		height:120px;
	}
	.wrap-email-contacts {
		padding:10px 10px;
		margin: 0;
		a {
			font-size: 18px;
		}
	}

	.margin-bottom {
		margin-bottom: 15px;
	}
	.wrap-add-contacts .head_add {
		.h2 {
			float: none;
			font-size: 23px;
		}
		.reclama-link {
			float: none;
			display: inline-block;
			vertical-align: top;
			margin:0 0 20px;
			font-size: 16px;
		}
	}
	.wrap-tabs {
		margin:20px 0 0;
	}
	.wrap-input .check {
		width: 25px;
		height:25px;
		.icon-check {
			@include sprite($check-small);
		}
	}
	.wrap-input.relative-check p {
		padding:0 0 0 30px;
		font-size: 13px;
	}


	.contact-event {
		padding:0 0 40px;
		.map-event {
			iframe {
				height:190px;
			}
		}
	}
	.tovar-box {
		margin:0 0 25px;
		.name-tovar {
			font-size: 12px;
			height:46px;
			margin:0 0 10px;
		}
		.prise-tovar {
			font-size: 14px;
			margin:0 0 10px;
		}
		.tovar-img {
			height:95px;
			line-height: 80px;
		}
		.about-tovar {
			padding:10px 5px 15px;
		}
		.btn-b {
			max-width:115px;
			font-size: 12px;
			padding:7px 12px;
		}
	}
	.prise .delivery {
		margin:0 0 40px;
		p {
			margin: 0 0 10px;
		}
	}
	.vr-recomenduet .container {
		.icon-rec {
			height:48px;
			width:48px;
			bottom:10px;
		}
	}
	.name-clubs {
		padding:30px 0 45px;
		.c-title {
			font-size: 14px;
			margin: 0 0 15px;
		}
		.h1 {
			font-size: 30px;
			margin: 0 0 15px;
		}
	}
	.prise {
		padding:40px 0 20px;
		ul {
			column-count: 1;
			column-gap: 0px;
			li {
				margin:0 0 20px;
				max-width: 100%;
				padding:0 0 0 20px;
			}
		}
	}
	.game-list ul li {
		width:100%;
		padding:0 15px 0 20px;
	}
	.contact-club {
		padding: 45px 0 0px;
		.map iframe {
			height:190px;
		}
	}
	.c-row {
		margin:0 0 25px;
	}
	.reviews-content {
		margin: 0 0 25px 15px;
	}
	.reviews-box {
		width:100%;
	}
	.contact-club {
		.contacts-box {
			margin:0;
			border: none;
			padding:0;
			min-height: inherit;
		}
	}
	.contact-club .map {
		margin: 0 0 30px;
	}
	.head {
		font-size: 23px;
		margin: 0 0 25px;
	}
	.info-list{
		margin: 30px 0 0;
		text-align: left;
		padding:25px 10px;
		ul li {
			width:100%;
			max-width:100%;
			margin:0 0 15px;
			position: relative;
			min-height: 23px;
			padding:0 15px 0 0;
			.info-wrap-img {
				text-align: center;
				width: 33px;
				display: inline-block;
				vertical-align: middle;
				position: absolute;
				top: 0;
				left: 0;
			}
			img {
				margin:0;
				vertical-align: middle;
				max-height: 25px;
			}
			span {
				font-size: 14px;
				display: inline-block;
				vertical-align: middle;
				padding:0 0 0 45px;
			}
		}
	}
	.wrap-img-club {
		margin:0 0 10px;
		img {
			margin:0 0 30px;
			width:100%;
		}
	}
	.close-menu {
		top: 27px;
	}
	.wrapper-another-articles {
		padding:35px 0;
	}
	.news-block {
		margin:0 0 45px;
		p {
			font-size: 14px;
			line-height:18px;
			.content-box {
				font-size: 14px;
				line-height:18px;
				.wrap-icon-cube {
					margin:10px 0;
				}
				blockquote {
					margin:15px 0;
					p {
						font-size: 18px;
						line-height: 22px;
					}

				}
			}
		}
	}

	.wrap-one-news-img img {
		margin:0 0 30px;
	}
	.about-project-block {
		padding:20px 0 0;
	}
	.wrap-icon-cube {
		margin:15px 0;
		span {
			margin: 0 10px 0 0;
			width: 13px;
			height: 13px;
		}
	}
	.content-box {
		ul {
			margin:10px 0 10px 10px;
			li {
				padding: 0 0 0 20px;
				margin: 0 0 10px;
			}
		}
	}
	.wrap-sl .sl, .content-box blockquote p {
		font-size: 18px;
		line-height:22px;
	}
	.wrap-news {
		padding:20px 0 0;
	}
	.articles {
		padding:30px 0 0;
		&:last-child {
			padding:30px 0 20px;
		}
	}
	.news {
		.wrap-b {
			padding:15px 0 0;
			margin:0 0 15px;
			.h2 {
				font-size: 18px;
				height:inherit;
			}
			.date {
				font-size: 14px;
			}
		}
		.item-news {
			margin:0 0 25px;
			p {
				font-size: 14px;
			}
		}
	}
	.wrap-company-item {
		padding: 40px 0 40px;
	}
	.big-item.c-big-item {
		.g-item_about .h2 {
			font-size: 18px;
			height:inherit;
		}
		.adr, p {
			font-size: 14px;
			height:inherit;
		}
		.g-item .vr {
			font-size: 14px;
			padding:10px;
		}
	}
	.c-item-link {
		margin:0 0 35px;
	}
	.c-item-about {
		.h2 {
			font-size: 18px;
			margin:0 0 15px;
		}
		.adr {
			font-size: 13px;
			margin: 0 0 10px;
		}
		.vr {
			font-size: 13px;
			padding:10px;
		}
	}
	.c-item-about-top {
		padding:10px;
		p {
			font-size: 14px;
		}

	}
	.wrap-h1 {
		.h1 {
			font-size: 30px;
			margin: 0 0 30px;
		}
	}
	.text-p {
		font-size: 14px;
		margin: 0 0 20px;
	}
	.about-project img {
		padding: 0 15%;
		margin:0 0 30px;
	}
	.big-item .g-item-img {
		height: auto;
		line-height: inherit;
	}
	.breadcrumbs {
		margin: 10px 0 0;
	}
	.wpar-clubs_2 {
		padding:0px 0 20px;
	}
	.wpar-clubs_3 {
		padding:45px 0 20px;
	}
	.big-item {
		& + .wpar-clubs_3 {
			padding-top: 45px;
		}
	}
	.club .h1 {
		font-size: 30px;
	}
	.g-item_about {
		.g-item_about-top {
			padding: 14px 10px;
		}
		.h2 {
			font-size: 18px;
			height: auto;
		}
	}
	.filter .filter-head{
		font-size: 14px;
		margin:0 10px 0 0;
	}
	.custom-select-button {
		padding: 9px 40px 9px 10px;
		&:after {
			right:10px;
		}
	}
	.custom-select-ul {
		padding:20px 10px;
		li {
			font-size: 14px;
			margin:0 0 15px;
			&:last-child {
				margin:0 0 25px;
			}
		}
	}
	.custom-select-button {
		width:215px;
		font-size: 14px;
	}

	.g-item {
		margin:0 0 30px;
		.vr {
			height:auto;
			font-size:13px;
			padding:12px 10px;
			&.dop-class {
				height:auto;
			}
		}
	}
	.g-item-img {
		height:auto;
		line-height: inherit;
		.hover-block {
			padding: 15px 10px;
			p {
				bottom: 10px;
				left: 10px;
				right: 10px;
				max-height: 110px;
			}
		}

	}
	.first-block {
		padding:30px 0;
	}
	.bg-gray {
		padding:40px 0 60px;
	}
	.post {
		margin:0 0 20px;
		.h3 {
			font-size: 18px;
			height:auto;
			margin:0 0 10px;
		}
		.post-date {
			margin:0 0 10px;
		}
	}
	.footer-bottom {
		p {
			font-size: 10px;
		}
	}
	.btn-b {
		font-size: 16px;
	}
	.mob-list-1 {
		display: block;
	}
	.main-mnu_1 {
		padding:30px 10px;
		max-width: 100%;
			 .wrap-search input {
				 width: 237px;
			 }
	}
	.wrap_mail-search {
		padding: 0 65px 30px 0;
	}
	.mob-list {
		padding: 0 0 0 10px;
		li a {
			font-size: 14px;
		}
	}
	.header_bottom {
		display: none;
	}
	.logo p {
		display: none;
	}
	.header_top {
		padding: 15px 0 !important;
	}
	.case {
		.wrap-img {
			position: absolute;
			top: -40px;
			right:15px;
			margin:0;
			img {
				height:70px;
			}
		}
		.h2 {
			padding: 15px 0 0 0;
		}
		a {
			padding: 60px 15px 20px;
			min-height: inherit;
		}
		p {
			font-size: 14px;
			height:auto;
		}
	}
	.h2 {
		font-size: 28px;
	}
	.another-articles .h2 {
		font-size: 23px;
		margin: 0 0 25px;
	}
	.h1 {
		font-size: 35px;
	}
	.wrap-home-block .home-block {
		margin: 15px 0;
		min-height:190px;
		padding: 35px 0 0 10px;
	}
	.toBottom {
		display: none;
	}
	.logo img {
		max-width:150px;
	}
	.case {
		margin: 0 0 30px 10px;
	}
	.wrap-cases {
		padding: 45px 0 20px;
	}
	.h3 {
		font-size: 30px;
		margin: 0 0 20px;
	}
	.about-project {
		padding: 40px 0 60px;
		.wrap-text p {
			font-size: 14px;
		}
	}
	#footer {
		.wrap-email{
			text-align: center
		}
		.f-right {
			padding:20px 0;
			text-align: center;
			.margin {
				margin-bottom: 15px;
			}
		}
	}
	.social {
		margin: 0 0 15px;
	}
	.copyright {
		text-align: center;
		span, a {
			display: block;
			margin:0;
			padding:0;
		}
		span {
			&:after {
				display: none;
			}
		}
	}
	.shop-page {
		padding:30px 0 20px;
		.case {
			a {
				min-height:inherit;
				padding:15px 10px;
				.h2 {
					font-size: 21px;
					margin:0 0 10px;
				}
			}
			p {
				height:inherit;
			}
		}
	}


	//06.03.19
	.g-item_main-about, .g-item-img .hover-block {
		padding: 6px 8px;
	}

	.hover-block {
		> p {
			font-size: 13px!important;
		}
	}

	.club-filter {
		margin-bottom: 14px;
	}

	.club-filter .filter-option.pull-left,
	.club-filter .bs-searchbox,
	.club-filter .dropdown-menu.inner,
	.club-filter li .text {
		font-size: 14px!important;
	}

	.club-filter .custom-select-button {
		height: 43px;
	}

	.club-filter.form-group .btn-default {
		padding: 9px 40px 9px 10px!important;
		height: 43px;
	}

	.club-filter.form-group .form-control {
		height: 43px;
	}

	.club-filter {
		min-height: 43px;
	}

	.bs-searchbox {
		margin: -15px -10px 19px -10px;
	}

	#filter-button {
		height: 43px;
		font-size: 14px;
		width: calc(50% - 10px);
		margin-bottom: 15px;
	}

	.filter-2-buttons {
		margin-bottom: 6px;
	}

	.filter-2-buttons button {
		margin-bottom: 14px;
		width: calc(50% - 20px);
		font-size: 14px;
		height: 39px;
	}

	.filter-preloader-2 {
		top: -55px;
	}

	.empty-club-filter p {
		font-size: 16px!important;
	}

	.head-with-btn {
		padding-right: 0;

		span {
			display: block;
		}
	}

	.feedback-btn {
		position: static;
		width: 140px;
		height: 43px;
		font-size: 14px;
		margin-top: 20px;
	}

	.modal-title {
		font-size: 16px;
	}

	.rec-article-link {
		margin-bottom: 30px;
	}

	.rec-article-img {
		height: auto;
		line-height: inherit;

		img {
			width: 100%;
		}
	}

	.rec-article-title {
		height: auto;
	}

	.rec-article-short {
		height: auto;
	}

	.rec-article-footer {
		font-size: 13px;
	}

	.rec-article-footer > div {
		height: 48px;
		line-height: 48px;
	}

	.rec-article-title .h2 {
		font-size: 18px;
	}

	.rec-articles-title .h2 {
		font-size: 23px;
		margin-bottom: 25px;
	}

	.wrapper-rec-articles {
		padding: 30px 0 0;
	}
}

@media screen and (max-width: 480px) {
	.adr {
		height:inherit;
	}
	.wrap-search {
		margin: 0 0 0 15px;
	}
	.main-mnu_1 .wrap-search input {
		width: 222px;
	}
}