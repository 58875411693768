// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$big-cube-name: 'big-cube';
$big-cube-x: 0px;
$big-cube-y: 53px;
$big-cube-offset-x: 0px;
$big-cube-offset-y: -53px;
$big-cube-width: 20px;
$big-cube-height: 19px;
$big-cube-total-width: 36px;
$big-cube-total-height: 335px;
$big-cube-image: '../sprites/sprite.png';
$big-cube: (0px, 53px, 0px, -53px, 20px, 19px, 36px, 335px, '../sprites/sprite.png', 'big-cube', );
$check-small-name: 'check-small';
$check-small-x: 0px;
$check-small-y: 34px;
$check-small-offset-x: 0px;
$check-small-offset-y: -34px;
$check-small-width: 15px;
$check-small-height: 14px;
$check-small-total-width: 36px;
$check-small-total-height: 335px;
$check-small-image: '../sprites/sprite.png';
$check-small: (0px, 34px, 0px, -34px, 15px, 14px, 36px, 335px, '../sprites/sprite.png', 'check-small', );
$check-name: 'check';
$check-x: 0px;
$check-y: 102px;
$check-offset-x: 0px;
$check-offset-y: -102px;
$check-width: 21px;
$check-height: 20px;
$check-total-width: 36px;
$check-total-height: 335px;
$check-image: '../sprites/sprite.png';
$check: (0px, 102px, 0px, -102px, 21px, 20px, 36px, 335px, '../sprites/sprite.png', 'check', );
$cube-list-name: 'cube-list';
$cube-list-x: 0px;
$cube-list-y: 16px;
$cube-list-offset-x: 0px;
$cube-list-offset-y: -16px;
$cube-list-width: 13px;
$cube-list-height: 13px;
$cube-list-total-width: 36px;
$cube-list-total-height: 335px;
$cube-list-image: '../sprites/sprite.png';
$cube-list: (0px, 16px, 0px, -16px, 13px, 13px, 36px, 335px, '../sprites/sprite.png', 'cube-list', );
$head-tooltip-name: 'head-tooltip';
$head-tooltip-x: 0px;
$head-tooltip-y: 268px;
$head-tooltip-offset-x: 0px;
$head-tooltip-offset-y: -268px;
$head-tooltip-width: 26px;
$head-tooltip-height: 26px;
$head-tooltip-total-width: 36px;
$head-tooltip-total-height: 335px;
$head-tooltip-image: '../sprites/sprite.png';
$head-tooltip: (0px, 268px, 0px, -268px, 26px, 26px, 36px, 335px, '../sprites/sprite.png', 'head-tooltip', );
$icon-r-name: 'icon-R';
$icon-r-x: 0px;
$icon-r-y: 181px;
$icon-r-offset-x: 0px;
$icon-r-offset-y: -181px;
$icon-r-width: 27px;
$icon-r-height: 24px;
$icon-r-total-width: 36px;
$icon-r-total-height: 335px;
$icon-r-image: '../sprites/sprite.png';
$icon-r: (0px, 181px, 0px, -181px, 27px, 24px, 36px, 335px, '../sprites/sprite.png', 'icon-R', );
$icon-card-name: 'icon-card';
$icon-card-x: 0px;
$icon-card-y: 77px;
$icon-card-offset-x: 0px;
$icon-card-offset-y: -77px;
$icon-card-width: 27px;
$icon-card-height: 20px;
$icon-card-total-width: 36px;
$icon-card-total-height: 335px;
$icon-card-image: '../sprites/sprite.png';
$icon-card: (0px, 77px, 0px, -77px, 27px, 20px, 36px, 335px, '../sprites/sprite.png', 'icon-card', );
$icon-cube-name: 'icon-cube';
$icon-cube-x: 0px;
$icon-cube-y: 0px;
$icon-cube-offset-x: 0px;
$icon-cube-offset-y: 0px;
$icon-cube-width: 11px;
$icon-cube-height: 11px;
$icon-cube-total-width: 36px;
$icon-cube-total-height: 335px;
$icon-cube-image: '../sprites/sprite.png';
$icon-cube: (0px, 0px, 0px, 0px, 11px, 11px, 36px, 335px, '../sprites/sprite.png', 'icon-cube', );
$icon-delivery-name: 'icon-delivery';
$icon-delivery-x: 0px;
$icon-delivery-y: 127px;
$icon-delivery-offset-x: 0px;
$icon-delivery-offset-y: -127px;
$icon-delivery-width: 31px;
$icon-delivery-height: 20px;
$icon-delivery-total-width: 36px;
$icon-delivery-total-height: 335px;
$icon-delivery-image: '../sprites/sprite.png';
$icon-delivery: (0px, 127px, 0px, -127px, 31px, 20px, 36px, 335px, '../sprites/sprite.png', 'icon-delivery', );
$icon-r-blue-name: 'icon-r-blue';
$icon-r-blue-x: 0px;
$icon-r-blue-y: 152px;
$icon-r-blue-offset-x: 0px;
$icon-r-blue-offset-y: -152px;
$icon-r-blue-width: 27px;
$icon-r-blue-height: 24px;
$icon-r-blue-total-width: 36px;
$icon-r-blue-total-height: 335px;
$icon-r-blue-image: '../sprites/sprite.png';
$icon-r-blue: (0px, 152px, 0px, -152px, 27px, 24px, 36px, 335px, '../sprites/sprite.png', 'icon-r-blue', );
$icon-user-blue-name: 'icon-user-blue';
$icon-user-blue-x: 0px;
$icon-user-blue-y: 210px;
$icon-user-blue-offset-x: 0px;
$icon-user-blue-offset-y: -210px;
$icon-user-blue-width: 24px;
$icon-user-blue-height: 24px;
$icon-user-blue-total-width: 36px;
$icon-user-blue-total-height: 335px;
$icon-user-blue-image: '../sprites/sprite.png';
$icon-user-blue: (0px, 210px, 0px, -210px, 24px, 24px, 36px, 335px, '../sprites/sprite.png', 'icon-user-blue', );
$icon-user-name: 'icon-user';
$icon-user-x: 0px;
$icon-user-y: 239px;
$icon-user-offset-x: 0px;
$icon-user-offset-y: -239px;
$icon-user-width: 24px;
$icon-user-height: 24px;
$icon-user-total-width: 36px;
$icon-user-total-height: 335px;
$icon-user-image: '../sprites/sprite.png';
$icon-user: (0px, 239px, 0px, -239px, 24px, 24px, 36px, 335px, '../sprites/sprite.png', 'icon-user', );
$quotes-name: 'quotes';
$quotes-x: 0px;
$quotes-y: 299px;
$quotes-offset-x: 0px;
$quotes-offset-y: -299px;
$quotes-width: 36px;
$quotes-height: 36px;
$quotes-total-width: 36px;
$quotes-total-height: 335px;
$quotes-image: '../sprites/sprite.png';
$quotes: (0px, 299px, 0px, -299px, 36px, 36px, 36px, 335px, '../sprites/sprite.png', 'quotes', );
$spritesheet-width: 36px;
$spritesheet-height: 335px;
$spritesheet-image: '../sprites/sprite.png';
$spritesheet-sprites: ($big-cube, $check-small, $check, $cube-list, $head-tooltip, $icon-r, $icon-card, $icon-cube, $icon-delivery, $icon-r-blue, $icon-user-blue, $icon-user, $quotes, );
$spritesheet: (36px, 335px, '../sprites/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
